<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 41" fill="none" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 44" clip-path="url(#a)">
      <path
        data-name="Path 32"
        d="M16.55 17.142h-6.343a1.35 1.35 0 0 1-1.348-1.347v-2.4a1.35 1.35 0 0 1 1.351-1.351h6.339a1.35 1.35 0 0 1 1.351 1.351v2.4a1.348 1.348 0 0 1-1.35 1.351"
        fill="#f55c04"
      />
      <path
        data-name="Path 33"
        d="M5.44 14.759h13.2a.552.552 0 0 0 .5-.791l-1.988-4.106a2.009 2.009 0 0 1 .019-1.762l1.74-3.443a.553.553 0 0 0-.491-.8H6.02a.582.582 0 0 0-.58.582Zm-.72 7.37a.72.72 0 0 1-.72-.72V4.436a2.024 2.024 0 0 1 2.02-2.022h12.4A1.991 1.991 0 0 1 20.195 5.3l-1.743 3.448a.557.557 0 0 0-.005.488l1.983 4.106a1.993 1.993 0 0 1-1.789 2.858h-13.2v5.21a.719.719 0 0 1-.72.719"
        fill="#333"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>