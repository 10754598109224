<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6">
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 73" fill="none" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 266">
      <g data-name="Group 108" clip-path="url(#a)">
        <path
          data-name="Path 89"
          d="M17.607 3.312a2.733 2.733 0 0 0-3.861 0l-1.721 1.722-1.722-1.722a2.73 2.73 0 0 0-3.861 3.861l.19.189h10.785l.19-.189a2.73 2.73 0 0 0 0-3.861"
          fill="#f55c04"
        />
        <path
          data-name="Path 90"
          d="M20.749 9.809a1.619 1.619 0 0 1-1.618 1.618H4.917a1.619 1.619 0 0 1-1.618-1.618v-.357a1.619 1.619 0 0 1 1.618-1.618h14.209a1.619 1.619 0 0 1 1.618 1.618v.357Zm-1.748 7.858a2.962 2.962 0 0 1-2.959 2.959H8.007a2.963 2.963 0 0 1-2.96-2.959v-4.8h13.954Zm.128-11.273H4.917a3.061 3.061 0 0 0-3.059 3.059v.357a3.062 3.062 0 0 0 1.748 2.763v5.1a4.4 4.4 0 0 0 4.4 4.4h8.034a4.4 4.4 0 0 0 4.4-4.4v-5.1a3.061 3.061 0 0 0 1.748-2.763v-.357a3.061 3.061 0 0 0-3.059-3.059"
          fill="#333"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>