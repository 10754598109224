<template>
  <div
    class="absolute flex flex-col-reverse text-sm font-medium text-gray-900 inset-0 h-full z-50"
  >
    <transition
      enter-active-class="transition-opacity ease-linear duration-300"
      leave-active-class="transition-opacity ease-linear duration-300"
      enter-class="opacity-0"
      leave-to-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      @after-leave="afterLeave"
    >
      <div
        v-if="active"
        class="fixed inset-0 bg-black bg-opacity-25 z-0"
        aria-hidden="true"
        @click="outsideClick"
      ></div>
    </transition>

    <transition
      enter-active-class="transition ease-in-out duration-300 transform"
      leave-active-class="transition ease-in-out duration-300 transform"
      enter-class="translate-y-full"
      leave-to-class="translate-y-full"
      enter-to-class="translate-y-0"
      leave-class="translate-y-0"
    >
      <div :class="_class" v-if="active">
        <div
          class="bg-white rounded-t-lg overflow-hidden max-w-screen-sm mx-auto relative max-h-full"
        >
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    outsideClickable: {
      type: Boolean,
    },
    _class: {
      type: String,
      default: "  z-10 max-app-h",
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    show() {
      this.active = true;
    },
    close() {
      this.active = false;
    },
    afterLeave() {
      this.$emit("close");
    },
    outsideClick() {
      if (!this.outsideClickable) return;
      this.close();
    },
  },
  mounted() {
    this.show();
  },
};
</script>

<style lang="scss" scoped></style>
