<template>
  <div class="relative bg-f8">
    <div
      class="flex items-center justify-between max-w-screen-xl m-auto w-full flex-none"
    >
      <div class="sm:px-0 pl-1 pt-4 pb-2">
        <nuxt-link :to="localePath(loggedin ? '/home' : '/')"
          ><img src="/logo.png" alt="Unknoown" class="sm:h-6 h-5"
        /></nuxt-link>
      </div>

      <div class="sm:hidden">
        <button
          type="button"
          class="btn-icon bg-f8"
          aria-expanded="false"
          @click="toggleMenu"
        >
          <MenuIcon />
        </button>
      </div>

      <div
        class="md:flex gap-x-4 items-center tracking-wider text-sm pr-4 2xl:pr-0 hidden"
      >
        <!-- <nuxt-link to="/dating">盲盒交友</nuxt-link> -->
        <!-- <nuxt-link to="/shop" class="text-primary font-bold">U</nuxt-link> -->

        <template v-if="!loggedin">
          <a href="https://ushop.unknoown.com" target="_blank">U.Shop</a>
          <nuxt-link to="/auth/login">登入</nuxt-link>
          <nuxt-link to="/auth/register" class="text-primary font-bold">註冊</nuxt-link>
        </template>

        <template v-for="(nav, index) in navs">
          <nuxt-link :key="index" :to="localePath(nav.to)" v-if="showable(nav)">{{
            nav.text
          }}</nuxt-link>
        </template>
      </div>
    </div>

    <van-popup v-model="showMenu" position="left" class="w-4/5 h-full">
      <div class="divide-y-2 divide-gray-50">
        <div class="px-5 pt-5 pb-6">
          <div class="flex items-center justify-between">
            <div>
              <nuxt-link @click.native="toggleMenu" to="/"
                ><img src="/logo.png" alt="Unknoown" class="2xl:w-40 w-32"
              /></nuxt-link>
            </div>
            <div>
              <button type="button" class="btn-icon bg-white" @click="toggleMenu">
                <XIcon />
              </button>
            </div>
          </div>
          <div class="mt-6">
            <nav class="grid gap-y-8">
              <a
                class="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                href="https://ushop.unknoown.com"
                v-if="!loggedin"
              >
                <StarIcon class="flex-shrink-0 text-primary" />
                <span class="ml-3 text-base font-medium text-gray-900"> U.Shop </span></a
              >
              <template v-for="nav in navs">
                <nuxt-link
                  :key="nav.text"
                  :to="localePath(nav.to)"
                  @click.native="toggleMenu"
                  class="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                  v-if="showable(nav)"
                >
                  <component :is="nav.icon" class="flex-shrink-0 text-primary" />
                  <span class="ml-3 text-base font-medium text-gray-900">{{
                    nav.text
                  }}</span>
                </nuxt-link>
              </template>
            </nav>
          </div>
        </div>
        <div class="space-y-6 py-6 px-5">
          <template v-if="!loggedin">
            <div>
              <nuxt-link @click.native="toggleMenu" :to="localePath('/auth/register')" class="btn"
                >註冊</nuxt-link
              >
              <p class="mt-6 text-center text-sm">
                已是會員？
                <nuxt-link
                  @click.native="toggleMenu"
                  :to="localePath('/auth/login')"
                  class="text-primary"
                  >登入</nuxt-link
                >
              </p>
            </div>
          </template>
          <template v-if="loggedin">
            <div>
              <a href="#" class="btn" @click.prevent="logout">登出</a>
            </div>
          </template>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
const navs = [
  {
    to: "/admin",
    icon: "ChartBarIcon",
    text: "Admin",
    required: {
      admin: true,
      login: true,
    },
  },

  {
    to: "/home",
    icon: "CollectionIcon",
    text: "匿名投稿頁",
    required: {
      login: true,
    },
  },

  {
    to: "/myshops",
    icon: "HomeIcon",
    text: "我的商店",
    required: {
      login: true,
    },
  },

  {
    to: "/home/me",
    icon: "UserIcon",
    text: "設置",
    required: {
      login: true,
    },
  },

  {
    to: "/docs/tutorial",
    icon: "ShieldCheckIcon",
    text: "使用教學",
    required: {
      login: false,
    },
  },
];

import {
  MenuIcon,
  XIcon,
  ChartBarIcon,
  CollectionIcon,
  ShieldCheckIcon,
  ThumbUpIcon,
  ChatAlt2Icon,
  ArchiveIcon,
  HomeIcon,
  UserIcon,
  StarIcon,
} from "@vue-hero-icons/outline";

export default {
  components: {
    MenuIcon,
    XIcon,
    ChartBarIcon,
    CollectionIcon,
    ShieldCheckIcon,
    ThumbUpIcon,
    ChatAlt2Icon,
    ArchiveIcon,
    HomeIcon,
    UserIcon,
    StarIcon,
  },
  data() {
    return {
      showMenu: false,
      navs,
    };
  },
  methods: {
    logout() {
      this.$auth.logout();
      this.showMenu = false;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    routerRedirect(path) {
      this.$router.push(this.localePath(path));
    },
    onlyForAdmin(bool) {
      if (bool) {
        return this.isAdmin;
      }

      return true;
    },
    showable(nav) {
      const { login, admin, chatable } = nav.required;

      if (!login && this.loggedin) return false;

      if (login && !this.loggedin) return false;

      if (admin && !this.isAdmin) return false;

      return true;
    },
  },
  computed: {
    isAdmin() {
      return this.me.admin;
    },
  },
};
</script>

<style lang="scss" scoped></style>
