<template>
  <div class="max-h-500 flex flex-col">
    <div class="flex-grow overflow-scroll px-4">
      <p class="text-sm whitespace-pre-wrap" v-text="terms" />
      <div class="h-10"></div>
    </div>

    <div class="flex-none bg-white pb-6 px-4">
      <button
        type="button"
        @click="$emit('changeto', 'signup', true)"
        class="
          font-bold
          text-sm
          py-4
          bg-black
          rounded-lg
          text-white
          w-full
          mt-4
        "
      >
        同意並接受
      </button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    terms() {
      return `本Boxboxmo澳門戀愛交友盲盒網站及應用程式（以下統稱“本服務”）由四葉草電腦有限公司（以下簡稱“四葉草”）管理及營運，讓註冊會員及任何登記聯絡資料之人士可以透過本服務結交對象。

當閣下註冊並成為本服務的會員或向四葉草提供聯絡資料時，即表示閣下已閱讀、瞭解並同意本條款及細則（以下簡稱“本條款”），以及任何相關的特別條款及細則（包括隱私政策、商品說明等）均規範四葉草向閣下提供本服務。

倘若閣下不同意本條款，敬請閣下不要註冊、登記聯絡資料及使用本服務。

1.服務
  1.四葉草作為服務提供者，在閣下同意並嚴格遵守本條款的前提下，將讓閣下透過提供其Instagram帳號、Wechat帳號、電話號碼而使用本服務。

  2.本條款是針對希望使用本服務之會員及留下聯絡資料之人士所訂立，只要閣下成功加入為本服務的會員或在閱讀及同意本條款後向四葉草提供聯絡資料，本條款將具有完全效力。如有任何服務或功能終止，閣下將仍受本條款下所應承擔的義務所約束，包括任何賠償、保證以及責任限制。

2.使用服務的義務
  1.為使用本服務，閣下須遵守本條款的所有規定。依本服務註冊表之要求提供閣下本人正確、最新的資料，且不得以第三人之名義登記資料或註冊為會員。
  若註冊本服務時，閣下使用第三人之名義的帳號登記資料或註冊為會員，將無法得到法律上的保護且必須爲此擔負所有責任。

  2.倘若閣下註冊為會員，閣下應及時維持並更新會員個人資料，確保其正確性。

3.修改條款
  1.閣下同意四葉草有權隨時修改本條款並通知閣下，閣下繼續登入或使用本服務即被視為閣下已收到有關的變更通知，並接受經修改的條款。

4.服務之修改、暫停及中斷
  1.於以下各種情況時，四葉草有權可以無須事前通知而暫停、撤銷或限制全部或部份服務，直至另行通知閣下：
    (a)提供服務可能導致四葉草違反任何適用法律；
    (b)四葉草合理認為有必要暫停服務，以便：
    (i)對本服務進行計劃性維護、修理或升級；
    (ii)保護本服務的完整性，或進行計劃以外的維護、修理或升級。

  2.若閣下提供任何錯誤或不實的資料、或未按指示提供資料、或欠缺必要之資料、或有重覆註冊帳號等情事時，四葉草亦有權不經事先通知，進行暫停或終止閣下會員的帳號，並拒絕閣下使用本服務之全部或部份。

5.服務爭議
  1.本服務所涉及之服務及資訊均由會員及第三方直接提供閣下，四葉草無法辨別及判斷服務及資訊之來源、真實性及完整性。若出現糾紛或不良結果，閣下明白及同意四葉草概不負責。然而，若閣下在本服務（即指盲盒開啟的七天內）提出合理證明本服務所涉及之服務及資訊因四葉草之原因（有關原因在任何情況下並不包括因第三方不同意接受閣下好友申請，第三方與閣下不聯絡而導致閣下不滿意等原因）而導致無法使用，四葉草同意原價退款閣下。

6.服務內容
  1.本服務將包括以下內容：
  I.戀愛溝通技巧教學
  II.戀愛盲盒抽取
  III.交友及拍拖技巧文章訂閱
  IV.感情專家一對一服務

7.閣下的責任
  1.為使用本服務，閣下需提供包括但不限於閣下相片，影片、電話號碼、電郵、Instagram帳號名稱、Wechat帳號、個人介紹等資料予四葉草。閣下知悉及同意四葉草因提供閣下服務所需而將閣下所提供的資料保存在四葉草網站中，並可能讓第三方訪問有關資料。

  2.閣下明白及同意須對自身行為、使用本服務又或倘若閣下使用本服務時與第三方之間的文件、文檔及通信，自行承擔責任。四葉草並無義務監控閣下的內容，亦沒有對內容又或當中所載的資料的準確性、完整性、適當性、合法性及正當性進行監控。

  3.對於閣下在本服務上和以外的行為四葉草恕不負責。閣下同意在與其他會員或第三方互動時小心謹慎，尤其當閣下決定在本服務之外進行交流或親自見面時。

  4.四葉草保留權利，隨時且無須事先通知下依照適用法律及法規，及／或配合行政當局，刪除或移除閣下的某些內容，或限制閣下登入或使用本服務。

  5.閣下不能上傳任何惡意文件或軟體至本服務。

8.會員之權利及義務
  1.倘若閣下註冊為會員，必須年滿18歲並按照會員註冊表之要求提供閣下本人正確、完整的資料。

  2.會員可推薦其他人註冊及加入成為新會員，為獎勵會員成功推薦新會員，四葉草將向該會員提供回贈福利，次數不限，有關回贈福利詳情須向四葉草後台查詢及申請發放。

9.廣告
  1.閣下同意四葉草、四葉草關係企業或四葉草的第三方夥伴可在本服務置入廣告。透過向四葉草提交關於本服務之建議或意見回饋，即表示您同意四葉草可就任何目的使用與分享這些意見回饋，且無須給付報酬給閣下。

10.免責條款
  1.閣下明確理解並同意，閣下承擔因使用本服務而產生的所有責任及風險。

  2.閣下明白及同意本服務，以及本服務上的任何信息及內容均依「現況」及「現有」的基礎提供的，並在法律允許的範圍內，四葉草不作任何明確或暗示的保證、陳述或認可，包括但不限於戀愛配對成功保證或有關本服務以及通過本服務或互聯網一般可得的任何資料、商品或服務的權利或非侵權性保證。閣下明白及同意四葉草無需為任何因使用或訪問本服務而生的直接或間接費用或損害負責。

3.因此，四葉草不會保證：
  I.本服務將滿足閣下的要求或將會一直可以被使用；
  II.使用本服務所獲得的結果均為準確或可靠的；
  III.閣下通過本服務獲得的任何商品、服務、資料或其他將符合閣下預期，以及；
  IV.糾正本服務的任何錯誤。

11.終止
  1.閣下可隨止終止使用本服務。終止本服務並不會免除閣下應負的任何義務或責任。在某些情況下，四葉草可隨時無須事先通知下終止或暫停所有或部分本服務、閣下的帳戶及終止或暫停閣下使用本服務。

  2.終止的原因包括但不限於因：
    I.閣下違反本條款；
    II.閣下要求取消或終止使用本服務；
    III.有權限當局的要求或命令；
    IV.向閣下提供本服務變為不法時；
    V.技術或保安問題；
    VI.閣下從事詐騙活動
    VII.閣下以非法或違法方式為目的使用本服務；
    VIII.閣下閒置帳戶超過一年。
    IX.閣下向四葉草提供的任何資料被發現是虛假或不實的。

  3.四葉草可自行決定終止、取消或暫停本服務，且四葉草對終止或暫停閣下的帳戶，或終止或暫停閣下使用本服務而導致的任何損害或損失，四葉草對閣下或任何第三方無須承擔責任。

  4.一旦終止閣下的帳戶，或終止閣下使用本服務，四葉草可能刪除四葉草系統內儲存於閣下的帳戶內的所有資料或數據。閣下明確理解及同意就任何數據損失或損害，包括閣下帳戶的內容，又或任何儲存於本服務的任何文件，又或任何因閣下未備份、恢復或回復閣下的內容有關的一切費用或開支，四葉草無須負責。

12.可分割的條款
  1.如本條款的任何規定，或將其應用於任何人士或情況時，被任何具有司法權限的法庭判定為無效或因任何原因不可執行，此等規定的無效性或不可執行性不應影響本條款其他規定的有效性及應用，此等其他規定須保持具有完全效力。

13.責任限制
  1.儘管本條款有任何相反協定，四葉草的合同責任、侵權責任(包括過失或違反法定義務)或因履行本服務而生或與之相關的責任所涉及的賠償總額，不論是否為任一單項索賠/訴因或所有索賠/訴因，也不論是否由共同訴因所造成的，將不超過澳門幣伍拾元正。

  2.對於任何後果性或間接性損失，四葉草並不對此承擔任何責任。

14.放棄
  1.四葉草未有堅持或嚴格執行本條款的任何規定不應被解釋為放棄任何規定或權利。

15.管轄法律及管轄權
  1.閣下對本服務的使用，以及本條款均應受中華人民共和國澳門特別行政區（“澳門特區”）法律管轄並依照其解釋。澳門特區法院有權管轄因閣下使用本服務而產生或與此相關的或因本條款而產生的任何索賠，然而，四葉草保留針對閣下違反本條款的行為在閣下的居住國內提出訴訟的權利。

如果您遇到任何故障或問題，請將錯誤訊息電郵至info@iclover.net。`;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>