<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 115" fill="none" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 270">
      <g data-name="Group 192" clip-path="url(#a)">
        <path
          data-name="Path 170"
          d="M6.118 19.066a8.84 8.84 0 0 0 11.7.091v-.091a3.724 3.724 0 0 0-3.723-3.723H9.836a3.722 3.722 0 0 0-3.723 3.723"
          fill="#f55c04"
        />
        <path
          data-name="Path 171"
          d="M12.021 3.873a8.126 8.126 0 1 0 8.127 8.126 8.136 8.136 0 0 0-8.127-8.126m0 17.693a9.566 9.566 0 1 1 9.567-9.567 9.577 9.577 0 0 1-9.567 9.567"
          fill="#333"
        />
        <path
          data-name="Path 172"
          d="M12.021 8.708a1.851 1.851 0 1 0 1.851 1.851 1.853 1.853 0 0 0-1.851-1.851m0 5.142a3.291 3.291 0 1 1 3.29-3.29 3.294 3.294 0 0 1-3.29 3.29"
          fill="#333"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>