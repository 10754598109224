<template>
  <div
    class="h-screen-ios h-screen max-h-app w-full relative flex flex-col items-stretch overflow-hidden sm:overflow-y-scroll sm:scrollbar-hide sm:min-h-max"
  >
    <div class="flex-none pt-safe">
      <vHeader />
    </div>
    <div
      class="flex-grow self-stretch overflow-y-scroll sm:overflow-y-visible sm:h-min scrollbar-hide h-full"
      id="__body"
    >
      <nuxt />
    </div>

    <div class="mb-safe flex-none border-t sm:border-0" id="__footer">
      <vFooter class="hidden sm:block" />
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/Header";

export default {
  middleware: ["auth", 'RequireSignup'],

  components: {
    vHeader,
  },
};
</script>
