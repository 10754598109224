export const state = () => ({
  social: {
    discord: 'https://discord.gg/Af4nFAJZzH',
    instagram: 'https://www.instagram.com/macau__play/',
    facebook: 'https://www.facebook.com/MPlay853'
  },
  title: 'MPlay 線上競技',
  about: `疫情期間不能出門，不如一齊打遊戲贏獎金💰？
  MPLAY澳門遊戲競技比賽 會舉辦各種線上遊戲比賽，大家可以喺屋企同對方競技比賽，💰贏獎金🏆 
  `
})

export const getters = {
  title({ title }) {
    return title
  },
  about({ about }) {
    return about
  },

  social({social}) {
    return social;
  }

}

export const mutations = {
  
}

export const actions = {
 
  

}
