export const state = () => ({
  total: 0,
  second: 0,
  withdraw: 0
})

export const getters = {
  wallet(state) {
    return state
  },
}

export const mutations = {
  SET_DATE(state, data) {
    state.total = data.total
    state.second = data.second_total
    state.withdraw = data.withdraw_total
  }
}

export const actions = {
  
  fetch({ commit }) {
    return this.$axios.get('/dating/wallet')
      .then(({ data }) => {
        commit('SET_DATE', data.data)
      });
  },

}
