export const state = () => ({
  show: false,
  step: {}
});


export const getters = {
  step:(state) => state.step,
  show:(state) => state.show,
};

export const mutations = {
  SET_SHOW_STATE(state, bool) {
    state.show = bool;
  },
  SET_STEP_STATE(state, step) {
    state.step = step;
  }
};

export const actions = {
  close({ commit }) {
    commit('SET_SHOW_STATE', false)
  },
  open({ commit }, { title, type }) {
    commit('SET_STEP_STATE', { title, type })
    commit('SET_SHOW_STATE', true)
  }
};