<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6">
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 7" fill="none" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 7">
      <g data-name="Group 6" clip-path="url(#a)">
        <path
          data-name="Path 6"
          d="M12.021 3.975a8.126 8.126 0 1 0 8.127 8.126 8.136 8.136 0 0 0-8.127-8.126m0 17.693a9.566 9.566 0 1 1 9.567-9.567 9.577 9.577 0 0 1-9.567 9.567"
          fill="#333"
        />
        <path
          data-name="Path 7"
          d="M7.153 10.039a1.2 1.2 0 0 0 1.278 1.1 1.2 1.2 0 0 0 1.279-1.1 1.2 1.2 0 0 0-1.279-1.1 1.2 1.2 0 0 0-1.278 1.1"
          fill="#333"
        />
        <path
          data-name="Path 8"
          d="M14.289 10.039a1.2 1.2 0 0 0 1.278 1.1 1.2 1.2 0 0 0 1.278-1.1 1.2 1.2 0 0 0-1.278-1.1 1.2 1.2 0 0 0-1.278 1.1"
          fill="#333"
        />
        <path
          data-name="Path 9"
          d="M9.126 15.318c0 1.069 1.3 1.935 2.9 1.935s2.895-.866 2.895-1.935-1.3-1.937-2.895-1.937-2.9.868-2.9 1.937"
          fill="#f55c04"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>