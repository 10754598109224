import { map, flatten } from "lodash";

export const state = () => ({
  media: []
})

export const getters = {
  media({media}) {
    return media
  }
}

export const mutations = {
  SET_MEDIA(state, media) {
    state.media = media
  }
}

export const actions = {
  fetchMedia({ commit }) {
    return this.$axios.get("/mysocial/media")
      .then(({data}) => {
        commit('SET_MEDIA', data.data)
      });
  }

}
