import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f2d7aeca = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _5c088ede = () => interopDefault(import('../pages/admin/index/index.vue' /* webpackChunkName: "pages/admin/index/index" */))
const _47d15382 = () => interopDefault(import('../pages/admin/index/ads/index.vue' /* webpackChunkName: "pages/admin/index/ads/index" */))
const _3ddae17c = () => interopDefault(import('../pages/admin/index/bbm/index.vue' /* webpackChunkName: "pages/admin/index/bbm/index" */))
const _949cc126 = () => interopDefault(import('../pages/admin/index/notify.vue' /* webpackChunkName: "pages/admin/index/notify" */))
const _4ae59b5e = () => interopDefault(import('../pages/admin/index/payments.vue' /* webpackChunkName: "pages/admin/index/payments" */))
const _380edc08 = () => interopDefault(import('../pages/admin/index/prompt.vue' /* webpackChunkName: "pages/admin/index/prompt" */))
const _1065d311 = () => interopDefault(import('../pages/admin/index/shippings.vue' /* webpackChunkName: "pages/admin/index/shippings" */))
const _f6f550cc = () => interopDefault(import('../pages/admin/index/shop.vue' /* webpackChunkName: "pages/admin/index/shop" */))
const _2e37b4ac = () => interopDefault(import('../pages/admin/index/shop/products/index.vue' /* webpackChunkName: "pages/admin/index/shop/products/index" */))
const _7f19a8dc = () => interopDefault(import('../pages/admin/index/shop/products/create.vue' /* webpackChunkName: "pages/admin/index/shop/products/create" */))
const _54760674 = () => interopDefault(import('../pages/admin/index/shop/products/_product.vue' /* webpackChunkName: "pages/admin/index/shop/products/_product" */))
const _584cf7ae = () => interopDefault(import('../pages/admin/index/withdraw.vue' /* webpackChunkName: "pages/admin/index/withdraw" */))
const _594b7d78 = () => interopDefault(import('../pages/admin/index/bbm/_uuid.vue' /* webpackChunkName: "pages/admin/index/bbm/_uuid" */))
const _41c5f868 = () => interopDefault(import('../pages/boxboxmo/index.vue' /* webpackChunkName: "pages/boxboxmo/index" */))
const _1781c05a = () => interopDefault(import('../pages/channels/index.vue' /* webpackChunkName: "pages/channels/index" */))
const _66ef153c = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _41ec8826 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _5e5fda29 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _766246b5 = () => interopDefault(import('../pages/home/assistant.vue' /* webpackChunkName: "pages/home/assistant" */))
const _13aa3178 = () => interopDefault(import('../pages/home/assistant/index.vue' /* webpackChunkName: "pages/home/assistant/index" */))
const _ed5f6f74 = () => interopDefault(import('../pages/home/assistant/create.vue' /* webpackChunkName: "pages/home/assistant/create" */))
const _b28df832 = () => interopDefault(import('../pages/home/assistant/default.vue' /* webpackChunkName: "pages/home/assistant/default" */))
const _878bc80e = () => interopDefault(import('../pages/home/assistant/posts.vue' /* webpackChunkName: "pages/home/assistant/posts" */))
const _5e895daf = () => interopDefault(import('../pages/home/assistant/_media.vue' /* webpackChunkName: "pages/home/assistant/_media" */))
const _6deca65e = () => interopDefault(import('../pages/home/me.vue' /* webpackChunkName: "pages/home/me" */))
const _f51f38d8 = () => interopDefault(import('../pages/home/password.vue' /* webpackChunkName: "pages/home/password" */))
const _254217d1 = () => interopDefault(import('../pages/home/signup.vue' /* webpackChunkName: "pages/home/signup" */))
const _2fdc139c = () => interopDefault(import('../pages/home/channels/create.vue' /* webpackChunkName: "pages/home/channels/create" */))
const _0bc9b50c = () => interopDefault(import('../pages/home/channels/_channel.vue' /* webpackChunkName: "pages/home/channels/_channel" */))
const _5d89ad55 = () => interopDefault(import('../pages/home/posts/_id.vue' /* webpackChunkName: "pages/home/posts/_id" */))
const _7d4052d1 = () => interopDefault(import('../pages/home/_id.vue' /* webpackChunkName: "pages/home/_id" */))
const _e0048ed8 = () => interopDefault(import('../pages/home/_id/index.vue' /* webpackChunkName: "pages/home/_id/index" */))
const _d124b6ac = () => interopDefault(import('../pages/home/_id/create.vue' /* webpackChunkName: "pages/home/_id/create" */))
const _7168f7a8 = () => interopDefault(import('../pages/home/_id/instagram_poster.vue' /* webpackChunkName: "pages/home/_id/instagram_poster" */))
const _396e24db = () => interopDefault(import('../pages/home/_id/payments.vue' /* webpackChunkName: "pages/home/_id/payments" */))
const _8ea3674e = () => interopDefault(import('../pages/home/_id/pending.vue' /* webpackChunkName: "pages/home/_id/pending" */))
const _388da315 = () => interopDefault(import('../pages/home/_id/posts.vue' /* webpackChunkName: "pages/home/_id/posts" */))
const _360a59cc = () => interopDefault(import('../pages/home/_id/preference/index.vue' /* webpackChunkName: "pages/home/_id/preference/index" */))
const _2851db76 = () => interopDefault(import('../pages/home/_id/private.vue' /* webpackChunkName: "pages/home/_id/private" */))
const _5d7db304 = () => interopDefault(import('../pages/home/_id/schedules.vue' /* webpackChunkName: "pages/home/_id/schedules" */))
const _4f363990 = () => interopDefault(import('../pages/home/_id/styles.vue' /* webpackChunkName: "pages/home/_id/styles" */))
const _468ce4fa = () => interopDefault(import('../pages/home/_id/preference/autopublish.vue' /* webpackChunkName: "pages/home/_id/preference/autopublish" */))
const _8a890502 = () => interopDefault(import('../pages/home/_id/preference/binding.vue' /* webpackChunkName: "pages/home/_id/preference/binding" */))
const _fb9377ec = () => interopDefault(import('../pages/home/_id/preference/scheduling.vue' /* webpackChunkName: "pages/home/_id/preference/scheduling" */))
const _e57ce13c = () => interopDefault(import('../pages/me/index.vue' /* webpackChunkName: "pages/me/index" */))
const _11f5c49d = () => interopDefault(import('../pages/myshops/index.vue' /* webpackChunkName: "pages/myshops/index" */))
const _f224cbe6 = () => interopDefault(import('../pages/permissions.vue' /* webpackChunkName: "pages/permissions" */))
const _46893e41 = () => interopDefault(import('../pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _f2da826e = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _2d38f973 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _c7392d50 = () => interopDefault(import('../pages/boxboxmo/chats/index.vue' /* webpackChunkName: "pages/boxboxmo/chats/index" */))
const _45b27583 = () => interopDefault(import('../pages/boxboxmo/lgbt.vue' /* webpackChunkName: "pages/boxboxmo/lgbt" */))
const _e2283bde = () => interopDefault(import('../pages/boxboxmo/me/index.vue' /* webpackChunkName: "pages/boxboxmo/me/index" */))
const _3649b3cc = () => interopDefault(import('../pages/boxboxmo/put/index.vue' /* webpackChunkName: "pages/boxboxmo/put/index" */))
const _c35c34ae = () => interopDefault(import('../pages/boxboxmo/recharges/index.vue' /* webpackChunkName: "pages/boxboxmo/recharges/index" */))
const _d5ec0b82 = () => interopDefault(import('../pages/boxboxmo/records/index.vue' /* webpackChunkName: "pages/boxboxmo/records/index" */))
const _04a8ede5 = () => interopDefault(import('../pages/docs/instagram.vue' /* webpackChunkName: "pages/docs/instagram" */))
const _4087997b = () => interopDefault(import('../pages/docs/privacy.vue' /* webpackChunkName: "pages/docs/privacy" */))
const _0f0c87fb = () => interopDefault(import('../pages/docs/services.vue' /* webpackChunkName: "pages/docs/services" */))
const _0cbdc14a = () => interopDefault(import('../pages/docs/tg_token.vue' /* webpackChunkName: "pages/docs/tg_token" */))
const _5b10311c = () => interopDefault(import('../pages/docs/threads.vue' /* webpackChunkName: "pages/docs/threads" */))
const _1cd722fb = () => interopDefault(import('../pages/docs/tutorial.vue' /* webpackChunkName: "pages/docs/tutorial" */))
const _90af92d4 = () => interopDefault(import('../pages/me/shop.vue' /* webpackChunkName: "pages/me/shop" */))
const _064a16ce = () => interopDefault(import('../pages/me/shop/index.vue' /* webpackChunkName: "pages/me/shop/index" */))
const _46ad55db = () => interopDefault(import('../pages/me/shop/dashboard.vue' /* webpackChunkName: "pages/me/shop/dashboard" */))
const _62e2dab1 = () => interopDefault(import('../pages/me/shop/orders/index.vue' /* webpackChunkName: "pages/me/shop/orders/index" */))
const _c65903a0 = () => interopDefault(import('../pages/me/shop/products/index.vue' /* webpackChunkName: "pages/me/shop/products/index" */))
const _4d9989ca = () => interopDefault(import('../pages/boxboxmo/me/edit.vue' /* webpackChunkName: "pages/boxboxmo/me/edit" */))
const _5c97455d = () => interopDefault(import('../pages/boxboxmo/me/invite/index.vue' /* webpackChunkName: "pages/boxboxmo/me/invite/index" */))
const _3c5bf39b = () => interopDefault(import('../pages/boxboxmo/me/withdraw.vue' /* webpackChunkName: "pages/boxboxmo/me/withdraw" */))
const _4fe1c01b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2797523d = () => interopDefault(import('../pages/boxboxmo/chats/_chat.vue' /* webpackChunkName: "pages/boxboxmo/chats/_chat" */))
const _2da5124c = () => interopDefault(import('../pages/boxboxmo/put/_no.vue' /* webpackChunkName: "pages/boxboxmo/put/_no" */))
const _6b3b0812 = () => interopDefault(import('../pages/boxboxmo/recharges/_no.vue' /* webpackChunkName: "pages/boxboxmo/recharges/_no" */))
const _51660a64 = () => interopDefault(import('../pages/boxboxmo/take/_no.vue' /* webpackChunkName: "pages/boxboxmo/take/_no" */))
const _11b335ef = () => interopDefault(import('../pages/channels/_channel/index.vue' /* webpackChunkName: "pages/channels/_channel/index" */))
const _79ccef13 = () => interopDefault(import('../pages/feed/_id/index.vue' /* webpackChunkName: "pages/feed/_id/index" */))
const _234ddb86 = () => interopDefault(import('../pages/me/_user/index.vue' /* webpackChunkName: "pages/me/_user/index" */))
const _32e7f620 = () => interopDefault(import('../pages/myshops/_shop.vue' /* webpackChunkName: "pages/myshops/_shop" */))
const _172b64a3 = () => interopDefault(import('../pages/myshops/_shop/index.vue' /* webpackChunkName: "pages/myshops/_shop/index" */))
const _0ac07cf8 = () => interopDefault(import('../pages/myshops/_shop/ads/index.vue' /* webpackChunkName: "pages/myshops/_shop/ads/index" */))
const _7110042f = () => interopDefault(import('../pages/myshops/_shop/assistant.vue' /* webpackChunkName: "pages/myshops/_shop/assistant" */))
const _115de1fa = () => interopDefault(import('../pages/myshops/_shop/instagram.vue' /* webpackChunkName: "pages/myshops/_shop/instagram" */))
const _bdffa932 = () => interopDefault(import('../pages/myshops/_shop/orders/index.vue' /* webpackChunkName: "pages/myshops/_shop/orders/index" */))
const _6f6f9923 = () => interopDefault(import('../pages/myshops/_shop/products.vue' /* webpackChunkName: "pages/myshops/_shop/products" */))
const _6e410d34 = () => interopDefault(import('../pages/myshops/_shop/products/index.vue' /* webpackChunkName: "pages/myshops/_shop/products/index" */))
const _63ceedbb = () => interopDefault(import('../pages/myshops/_shop/products/categories/index.vue' /* webpackChunkName: "pages/myshops/_shop/products/categories/index" */))
const _af926c2e = () => interopDefault(import('../pages/myshops/_shop/products/poster.vue' /* webpackChunkName: "pages/myshops/_shop/products/poster" */))
const _9c8f6942 = () => interopDefault(import('../pages/myshops/_shop/products/variations/index.vue' /* webpackChunkName: "pages/myshops/_shop/products/variations/index" */))
const _3f2b45cc = () => interopDefault(import('../pages/myshops/_shop/products/_product.vue' /* webpackChunkName: "pages/myshops/_shop/products/_product" */))
const _10ec70f6 = () => interopDefault(import('../pages/myshops/_shop/promocodes.vue' /* webpackChunkName: "pages/myshops/_shop/promocodes" */))
const _3d069e3e = () => interopDefault(import('../pages/myshops/_shop/setting.vue' /* webpackChunkName: "pages/myshops/_shop/setting" */))
const _a1416ab8 = () => interopDefault(import('../pages/myshops/_shop/setting/index.vue' /* webpackChunkName: "pages/myshops/_shop/setting/index" */))
const _70177f76 = () => interopDefault(import('../pages/myshops/_shop/setting/groupbuy.vue' /* webpackChunkName: "pages/myshops/_shop/setting/groupbuy" */))
const _319fe6f8 = () => interopDefault(import('../pages/myshops/_shop/setting/instagram.vue' /* webpackChunkName: "pages/myshops/_shop/setting/instagram" */))
const _1e1e7f1e = () => interopDefault(import('../pages/myshops/_shop/setting/off_day.vue' /* webpackChunkName: "pages/myshops/_shop/setting/off_day" */))
const _6fabe9c0 = () => interopDefault(import('../pages/myshops/_shop/setting/order.vue' /* webpackChunkName: "pages/myshops/_shop/setting/order" */))
const _35dcbf23 = () => interopDefault(import('../pages/myshops/_shop/setting/order_cut.vue' /* webpackChunkName: "pages/myshops/_shop/setting/order_cut" */))
const _19a63ecc = () => interopDefault(import('../pages/myshops/_shop/setting/shipping.vue' /* webpackChunkName: "pages/myshops/_shop/setting/shipping" */))
const _3c6b0a2b = () => interopDefault(import('../pages/myshops/_shop/setting/sms.vue' /* webpackChunkName: "pages/myshops/_shop/setting/sms" */))
const _4b1bb803 = () => interopDefault(import('../pages/myshops/_shop/setting/style.vue' /* webpackChunkName: "pages/myshops/_shop/setting/style" */))
const _bca8a192 = () => interopDefault(import('../pages/myshops/_shop/summary.vue' /* webpackChunkName: "pages/myshops/_shop/summary" */))
const _02a02ffc = () => interopDefault(import('../pages/myshops/_shop/variations/index.vue' /* webpackChunkName: "pages/myshops/_shop/variations/index" */))
const _35f217ec = () => interopDefault(import('../pages/myshops/_shop/orders/_order.vue' /* webpackChunkName: "pages/myshops/_shop/orders/_order" */))
const _4617656b = () => interopDefault(import('../pages/passcode/_username.vue' /* webpackChunkName: "pages/passcode/_username" */))
const _253547c5 = () => interopDefault(import('../pages/s/_shop/index.vue' /* webpackChunkName: "pages/s/_shop/index" */))
const _65448ff2 = () => interopDefault(import('../pages/feed/_id/payment.vue' /* webpackChunkName: "pages/feed/_id/payment" */))
const _7d3a071e = () => interopDefault(import('../pages/feed/_id/vip.vue' /* webpackChunkName: "pages/feed/_id/vip" */))
const _de8c2110 = () => interopDefault(import('../pages/s/_shop/me/index.vue' /* webpackChunkName: "pages/s/_shop/me/index" */))
const _44ee43f6 = () => interopDefault(import('../pages/s/_shop/orders/index.vue' /* webpackChunkName: "pages/s/_shop/orders/index" */))
const _fe6a713a = () => interopDefault(import('../pages/s/_shop/orders/checkout.vue' /* webpackChunkName: "pages/s/_shop/orders/checkout" */))
const _2caaa318 = () => interopDefault(import('../pages/channels/_channel/topics/_topic.vue' /* webpackChunkName: "pages/channels/_channel/topics/_topic" */))
const _3994952c = () => interopDefault(import('../pages/s/_shop/orders/_order.vue' /* webpackChunkName: "pages/s/_shop/orders/_order" */))
const _63df85ee = () => interopDefault(import('../pages/s/_shop/products/_product.vue' /* webpackChunkName: "pages/s/_shop/products/_product" */))
const _f84f1bf6 = () => interopDefault(import('../pages/me/_user/_uuid.vue' /* webpackChunkName: "pages/me/_user/_uuid" */))
const _51407652 = () => interopDefault(import('../pages/s/_shop/_.vue' /* webpackChunkName: "pages/s/_shop/_" */))
const _fd5154f4 = () => interopDefault(import('../pages/_id/index.vue' /* webpackChunkName: "pages/_id/index" */))
const _018fa1ae = () => interopDefault(import('../pages/_id/destroy.vue' /* webpackChunkName: "pages/_id/destroy" */))
const _29e74007 = () => interopDefault(import('../pages/_id/posts.vue' /* webpackChunkName: "pages/_id/posts" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _f2d7aeca,
    children: [{
      path: "",
      component: _5c088ede,
      name: "admin-index___zh-mo"
    }, {
      path: "ads",
      component: _47d15382,
      name: "admin-index-ads___zh-mo"
    }, {
      path: "bbm",
      component: _3ddae17c,
      name: "admin-index-bbm___zh-mo"
    }, {
      path: "notify",
      component: _949cc126,
      name: "admin-index-notify___zh-mo"
    }, {
      path: "payments",
      component: _4ae59b5e,
      name: "admin-index-payments___zh-mo"
    }, {
      path: "prompt",
      component: _380edc08,
      name: "admin-index-prompt___zh-mo"
    }, {
      path: "shippings",
      component: _1065d311,
      name: "admin-index-shippings___zh-mo"
    }, {
      path: "shop",
      component: _f6f550cc,
      name: "admin-index-shop___zh-mo",
      children: [{
        path: "products",
        component: _2e37b4ac,
        name: "admin-index-shop-products___zh-mo"
      }, {
        path: "products/create",
        component: _7f19a8dc,
        name: "admin-index-shop-products-create___zh-mo"
      }, {
        path: "products/:product",
        component: _54760674,
        name: "admin-index-shop-products-product___zh-mo"
      }]
    }, {
      path: "withdraw",
      component: _584cf7ae,
      name: "admin-index-withdraw___zh-mo"
    }, {
      path: "bbm/:uuid?",
      component: _594b7d78,
      name: "admin-index-bbm-uuid___zh-mo"
    }]
  }, {
    path: "/boxboxmo",
    component: _41c5f868,
    name: "boxboxmo___zh-mo"
  }, {
    path: "/channels",
    component: _1781c05a,
    name: "channels___zh-mo"
  }, {
    path: "/chat",
    component: _66ef153c,
    name: "chat___zh-mo"
  }, {
    path: "/home",
    component: _41ec8826,
    children: [{
      path: "",
      component: _5e5fda29,
      name: "home___zh-mo"
    }, {
      path: "assistant",
      component: _766246b5,
      children: [{
        path: "",
        component: _13aa3178,
        name: "home-assistant___zh-mo"
      }, {
        path: "create",
        component: _ed5f6f74,
        name: "home-assistant-create___zh-mo"
      }, {
        path: "default",
        component: _b28df832,
        name: "home-assistant-default___zh-mo"
      }, {
        path: "posts",
        component: _878bc80e,
        name: "home-assistant-posts___zh-mo"
      }, {
        path: ":media",
        component: _5e895daf,
        name: "home-assistant-media___zh-mo"
      }]
    }, {
      path: "me",
      component: _6deca65e,
      name: "home-me___zh-mo"
    }, {
      path: "password",
      component: _f51f38d8,
      name: "home-password___zh-mo"
    }, {
      path: "signup",
      component: _254217d1,
      name: "home-signup___zh-mo"
    }, {
      path: "channels/create",
      component: _2fdc139c,
      name: "home-channels-create___zh-mo"
    }, {
      path: "channels/:channel?",
      component: _0bc9b50c,
      name: "home-channels-channel___zh-mo"
    }, {
      path: "posts/:id?",
      component: _5d89ad55,
      name: "home-posts-id___zh-mo"
    }, {
      path: ":id",
      component: _7d4052d1,
      children: [{
        path: "",
        component: _e0048ed8,
        name: "home-id___zh-mo"
      }, {
        path: "create",
        component: _d124b6ac,
        name: "home-id-create___zh-mo"
      }, {
        path: "instagram_poster",
        component: _7168f7a8,
        name: "home-id-instagram_poster___zh-mo"
      }, {
        path: "payments",
        component: _396e24db,
        name: "home-id-payments___zh-mo"
      }, {
        path: "pending",
        component: _8ea3674e,
        name: "home-id-pending___zh-mo"
      }, {
        path: "posts",
        component: _388da315,
        name: "home-id-posts___zh-mo"
      }, {
        path: "preference",
        component: _360a59cc,
        name: "home-id-preference___zh-mo"
      }, {
        path: "private",
        component: _2851db76,
        name: "home-id-private___zh-mo"
      }, {
        path: "schedules",
        component: _5d7db304,
        name: "home-id-schedules___zh-mo"
      }, {
        path: "styles",
        component: _4f363990,
        name: "home-id-styles___zh-mo"
      }, {
        path: "preference/autopublish",
        component: _468ce4fa,
        name: "home-id-preference-autopublish___zh-mo"
      }, {
        path: "preference/binding",
        component: _8a890502,
        name: "home-id-preference-binding___zh-mo"
      }, {
        path: "preference/scheduling",
        component: _fb9377ec,
        name: "home-id-preference-scheduling___zh-mo"
      }]
    }]
  }, {
    path: "/me",
    component: _e57ce13c,
    name: "me___zh-mo"
  }, {
    path: "/myshops",
    component: _11f5c49d,
    name: "myshops___zh-mo"
  }, {
    path: "/permissions",
    component: _f224cbe6,
    name: "permissions___zh-mo"
  }, {
    path: "/receipt",
    component: _46893e41,
    name: "receipt___zh-mo"
  }, {
    path: "/auth/login",
    component: _f2da826e,
    name: "auth-login___zh-mo"
  }, {
    path: "/auth/register",
    component: _2d38f973,
    name: "auth-register___zh-mo"
  }, {
    path: "/boxboxmo/chats",
    component: _c7392d50,
    name: "boxboxmo-chats___zh-mo"
  }, {
    path: "/boxboxmo/lgbt",
    component: _45b27583,
    name: "boxboxmo-lgbt___zh-mo"
  }, {
    path: "/boxboxmo/me",
    component: _e2283bde,
    name: "boxboxmo-me___zh-mo"
  }, {
    path: "/boxboxmo/put",
    component: _3649b3cc,
    name: "boxboxmo-put___zh-mo"
  }, {
    path: "/boxboxmo/recharges",
    component: _c35c34ae,
    name: "boxboxmo-recharges___zh-mo"
  }, {
    path: "/boxboxmo/records",
    component: _d5ec0b82,
    name: "boxboxmo-records___zh-mo"
  }, {
    path: "/docs/instagram",
    component: _04a8ede5,
    name: "docs-instagram___zh-mo"
  }, {
    path: "/docs/privacy",
    component: _4087997b,
    name: "docs-privacy___zh-mo"
  }, {
    path: "/docs/services",
    component: _0f0c87fb,
    name: "docs-services___zh-mo"
  }, {
    path: "/docs/tg_token",
    component: _0cbdc14a,
    name: "docs-tg_token___zh-mo"
  }, {
    path: "/docs/threads",
    component: _5b10311c,
    name: "docs-threads___zh-mo"
  }, {
    path: "/docs/tutorial",
    component: _1cd722fb,
    name: "docs-tutorial___zh-mo"
  }, {
    path: "/me/shop",
    component: _90af92d4,
    children: [{
      path: "",
      component: _064a16ce,
      name: "me-shop___zh-mo"
    }, {
      path: "dashboard",
      component: _46ad55db,
      name: "me-shop-dashboard___zh-mo"
    }, {
      path: "orders",
      component: _62e2dab1,
      name: "me-shop-orders___zh-mo"
    }, {
      path: "products",
      component: _c65903a0,
      name: "me-shop-products___zh-mo"
    }]
  }, {
    path: "/boxboxmo/me/edit",
    component: _4d9989ca,
    name: "boxboxmo-me-edit___zh-mo"
  }, {
    path: "/boxboxmo/me/invite",
    component: _5c97455d,
    name: "boxboxmo-me-invite___zh-mo"
  }, {
    path: "/boxboxmo/me/withdraw",
    component: _3c5bf39b,
    name: "boxboxmo-me-withdraw___zh-mo"
  }, {
    path: "/",
    component: _4fe1c01b,
    name: "index___zh-mo"
  }, {
    path: "/boxboxmo/chats/:chat",
    component: _2797523d,
    name: "boxboxmo-chats-chat___zh-mo"
  }, {
    path: "/boxboxmo/put/:no",
    component: _2da5124c,
    name: "boxboxmo-put-no___zh-mo"
  }, {
    path: "/boxboxmo/recharges/:no",
    component: _6b3b0812,
    name: "boxboxmo-recharges-no___zh-mo"
  }, {
    path: "/boxboxmo/take/:no?",
    component: _51660a64,
    name: "boxboxmo-take-no___zh-mo"
  }, {
    path: "/channels/:channel",
    component: _11b335ef,
    name: "channels-channel___zh-mo"
  }, {
    path: "/feed/:id",
    component: _79ccef13,
    name: "feed-id___zh-mo"
  }, {
    path: "/me/:user",
    component: _234ddb86,
    name: "me-user___zh-mo"
  }, {
    path: "/myshops/:shop",
    component: _32e7f620,
    children: [{
      path: "",
      component: _172b64a3,
      name: "myshops-shop___zh-mo"
    }, {
      path: "ads",
      component: _0ac07cf8,
      name: "myshops-shop-ads___zh-mo"
    }, {
      path: "assistant",
      component: _7110042f,
      name: "myshops-shop-assistant___zh-mo"
    }, {
      path: "instagram",
      component: _115de1fa,
      name: "myshops-shop-instagram___zh-mo"
    }, {
      path: "orders",
      component: _bdffa932,
      name: "myshops-shop-orders___zh-mo"
    }, {
      path: "products",
      component: _6f6f9923,
      children: [{
        path: "",
        component: _6e410d34,
        name: "myshops-shop-products___zh-mo"
      }, {
        path: "categories",
        component: _63ceedbb,
        name: "myshops-shop-products-categories___zh-mo"
      }, {
        path: "poster",
        component: _af926c2e,
        name: "myshops-shop-products-poster___zh-mo"
      }, {
        path: "variations",
        component: _9c8f6942,
        name: "myshops-shop-products-variations___zh-mo"
      }, {
        path: ":product",
        component: _3f2b45cc,
        name: "myshops-shop-products-product___zh-mo"
      }]
    }, {
      path: "promocodes",
      component: _10ec70f6,
      name: "myshops-shop-promocodes___zh-mo"
    }, {
      path: "setting",
      component: _3d069e3e,
      children: [{
        path: "",
        component: _a1416ab8,
        name: "myshops-shop-setting___zh-mo"
      }, {
        path: "groupbuy",
        component: _70177f76,
        name: "myshops-shop-setting-groupbuy___zh-mo"
      }, {
        path: "instagram",
        component: _319fe6f8,
        name: "myshops-shop-setting-instagram___zh-mo"
      }, {
        path: "off_day",
        component: _1e1e7f1e,
        name: "myshops-shop-setting-off_day___zh-mo"
      }, {
        path: "order",
        component: _6fabe9c0,
        name: "myshops-shop-setting-order___zh-mo"
      }, {
        path: "order_cut",
        component: _35dcbf23,
        name: "myshops-shop-setting-order_cut___zh-mo"
      }, {
        path: "shipping",
        component: _19a63ecc,
        name: "myshops-shop-setting-shipping___zh-mo"
      }, {
        path: "sms",
        component: _3c6b0a2b,
        name: "myshops-shop-setting-sms___zh-mo"
      }, {
        path: "style",
        component: _4b1bb803,
        name: "myshops-shop-setting-style___zh-mo"
      }]
    }, {
      path: "summary",
      component: _bca8a192,
      name: "myshops-shop-summary___zh-mo"
    }, {
      path: "variations",
      component: _02a02ffc,
      name: "myshops-shop-variations___zh-mo"
    }, {
      path: "orders/:order",
      component: _35f217ec,
      name: "myshops-shop-orders-order___zh-mo"
    }]
  }, {
    path: "/passcode/:username?",
    component: _4617656b,
    name: "passcode-username___zh-mo"
  }, {
    path: "/s/:shop",
    component: _253547c5,
    name: "s-shop___zh-mo"
  }, {
    path: "/feed/:id?/payment",
    component: _65448ff2,
    name: "feed-id-payment___zh-mo"
  }, {
    path: "/feed/:id?/vip",
    component: _7d3a071e,
    name: "feed-id-vip___zh-mo"
  }, {
    path: "/s/:shop?/me",
    component: _de8c2110,
    name: "s-shop-me___zh-mo"
  }, {
    path: "/s/:shop?/orders",
    component: _44ee43f6,
    name: "s-shop-orders___zh-mo"
  }, {
    path: "/s/:shop?/orders/checkout",
    component: _fe6a713a,
    name: "s-shop-orders-checkout___zh-mo"
  }, {
    path: "/channels/:channel/topics/:topic?",
    component: _2caaa318,
    name: "channels-channel-topics-topic___zh-mo"
  }, {
    path: "/s/:shop?/orders/:order",
    component: _3994952c,
    name: "s-shop-orders-order___zh-mo"
  }, {
    path: "/s/:shop?/products/:product?",
    component: _63df85ee,
    name: "s-shop-products-product___zh-mo"
  }, {
    path: "/me/:user/:uuid",
    component: _f84f1bf6,
    name: "me-user-uuid___zh-mo"
  }, {
    path: "/s/:shop?/*",
    component: _51407652,
    name: "s-shop-all___zh-mo"
  }, {
    path: "/:id",
    component: _fd5154f4,
    name: "id___zh-mo"
  }, {
    path: "/:id/destroy",
    component: _018fa1ae,
    name: "id-destroy___zh-mo"
  }, {
    path: "/:id/posts",
    component: _29e74007,
    name: "id-posts___zh-mo"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
