<template>
  <div>
    <div class="flex justify-between items-center p-4">
      <h1 :class="[titleclass]" class="font-bold">{{ title }}</h1>
      <button type="button" :class="[titleclass]" class="p-2" @click="closeDialog">
        <XIcon />
      </button>
    </div>

    <ul :class="[textclass]" class="text-center font-medium px-4">
      <!-- <li v-if="sharable()" class="mb-4">
        <button
          type="button"
          class="btn py-3 w-full"
          :class="[bgclass, textclass]"
          @click="shareTo"
        >
          分享到
        </button>
      </li> -->
      <li class="mb-4">
        <button
          type="button"
          class="btn py-3 w-full"
          :class="[bgclass, textclass]"
          @click="copyUrl"
        >
          <link-icon class="inline-block mr-4" />取得連結
        </button>
      </li>
      <li class="mb-4">
        <button
          type="button"
          class="btn py-3 w-full"
          :class="[bgclass, textclass]"
          @click="copynshare"
        >
          <upload-icon class="inline-block mr-4" />分享
        </button>
      </li>
      <li class="mb-4" v-for="(item, index) in items" :key="index">
        <button
          type="button"
          class="btn py-3 w-full"
          :class="[bgclass, textclass]"
          @click="item.handler"
        >
          {{ item.text }}
        </button>
      </li>
      <!-- <li >
        <button
          type="button"
          class="btn  py-3 w-full"
          :class="[]"
          @click="openDefaultBrowser"
        >
          以預設瀏覽器打開
        </button>
      </li> -->
    </ul>
    <div class="h-40"></div>
  </div>
</template>

<script>
import { XIcon, LinkIcon, UploadIcon } from "@vue-hero-icons/outline";
import clipboard from "copy-to-clipboard";
import { useShare } from "@vueuse/core";

export default {
  components: {
    XIcon,
    LinkIcon,
    UploadIcon,
  },
  props: {
    sharedata: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: "分享",
    },
    titleclass: {
      type: String,
      default: "text-floral-primary",
    },
    textclass: {
      type: String,
      default: "text-floral-text",
    },
    bgclass: {
      type: String,
      default: "bg-floral-primary",
    },
    items: {
      type: Array,
      default: () => [],
    },
    sharetext: {
      type: String,
    },
  },
  methods: {
    async shareTo() {
      try {
        if (this.inapp) {
          await window.flutter_inappwebview.callHandler("share", this.sharedata);
        } else {
          const { share } = useShare();
          await share(this.sharedata);
        }
        this.closeDialog();
      } catch (e) {
        console.error(e);
      }
    },
    copynshare() {
      clipboard(this.sharetext || this.sharedata.text);
      this.$loader.success(`已複製連結，貼上至你的朋友的聊天室`);
      if (this.sharable()) this.shareTo();
      this.closeDialog();
    },
    async copyUrl() {
      await clipboard(this.sharedata.url);
      this.$loader.success(`已複製連結，貼上至你的朋友的聊天室`);
      this.closeDialog();
    },
    openDefaultBrowser() {
      child_process.exec(`start ${this.sharedata.url}`);
      // open(this.sharedata.url);
      this.closeDialog();
    },
    sharable() {
      const { isSupported } = useShare();
      return isSupported.value;
    },
    closeDialog() {
      this.$parent.close();
    },
  },
};
</script>

<style lang="css" scoped></style>
