<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.42 43.42">
    <g data-name="Layer 2">
      <path
        d="M21.71 0a21.71 21.71 0 1 0 21.71 21.71A21.74 21.74 0 0 0 21.71 0Zm0 39.65a17.94 17.94 0 1 1 17.94-17.94 18 18 0 0 1-17.94 17.94Zm7.21-13.4a1.89 1.89 0 0 1-2.67 2.67l-5.87-5.87a1.89 1.89 0 0 1-.56-1.34V10.46a1.89 1.89 0 0 1 3.78 0v10.47Z"
        data-name="Layer 1"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>