export const state = () => ({
  posts: {
    meta: {},
    data: []
  }
})

export const getters = {
  posts(state) {
    return state.posts.data;
  },
  meta(state) {
    return state.posts.meta;
  }
}

export const mutations = {
  SET_DATA(state, data) {
    state.posts.data.push(...data.data)
    state.posts.meta = data.meta
  },
  CLEAR_DATA(state) {
    state.posts = {
      meta: {},
      data: []
    }
  }
}

export const actions = {
  fetch({ commit }, {id, query}) {
    return this.$axios.get(`/fb/${id}/posts`, {
      params: query
    })
      .then(resp => {
        commit('SET_DATA', resp.data)
      })
  },

  clear({commit}) {
    commit('CLEAR_DATA')
  }


}
