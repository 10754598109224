export const state = () => ({
  pages: [],
  meta: {},
  page: {
    social_media: []
  }
})

export const getters = {
  page(state) {
    return state.page
  },
  social({page}) {
    return page.social_media
  },
  threadsable({page}) {
    return page.autopost_threads
  }
}

export const mutations = {
  SET_PAGES(state, data) {
    state.pages = data.data
    state.meta = data.meta
  },

  SET_PAGE(state, data) {
    state.page = data.data
  }
}

export const actions = {
  fetchPages({ commit }) {

  },

  fetchPage({ commit }, page_id) {
    return this.$axios.get(`/pages/${page_id}`)
      .then(({ data }) => {
        commit('SET_PAGE', data)
      });
  },
  setCustomers({ commit }, customers) {
    commit('SET_CUSTOMERS', customers)
  }
}
