<template>
  <div class="flex flex-col min-h-screen ">
    <div class="flex-grow relative flex flex-col w-full">
      <nuxt />
    </div>

    <vFooter />
  </div>
</template>



<script>
export default {

};
</script>

<style lang="scss" scoped>
</style>