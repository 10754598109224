<template>
  <div>
    <p class="text-xs text-center text-gray-400">BoxBoxMo 澳門戀愛交友盲盒</p>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>