<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135 135">
      <defs>
        <linearGradient
          id="a"
          x1="67.5"
          y1="135"
          x2="67.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#1d93d2" />
          <stop offset="1" stop-color="#38b0e3" />
        </linearGradient>
      </defs>
      <g data-name="Layer 2">
        <g data-name="Graphic Elements">
          <circle cx="67.5" cy="67.5" r="67.5" fill="url(#a)" />
          <path
            d="m45.69 72.43 8 22.17s1 2.07 2.07 2.07 17-16.59 17-16.59l17.76-34.25L46 66.71Z"
            fill="#c8daea"
          />
          <path
            d="m56.31 78.12-1.54 16.34s-.64 5 4.36 0 9.8-8.87 9.8-8.87Z"
            fill="#a9c6d8"
          />
          <path
            d="m45.84 73.22-16.48-5.36s-2-.8-1.33-2.61c.13-.38.39-.69 1.18-1.24 3.65-2.55 67.56-25.52 67.56-25.52a5.5 5.5 0 0 1 2.87-.2 1.53 1.53 0 0 1 1.06 1.16 4.94 4.94 0 0 1 .14 1.45c0 .42 0 .81-.09 1.43-.39 6.28-12 53.15-12 53.15s-.69 2.75-3.19 2.84A4.59 4.59 0 0 1 82.18 97c-4.9-4.21-21.83-15.6-25.58-18.1a.68.68 0 0 1-.3-.5c-.06-.27.23-.59.23-.59S86 51.62 86.81 48.87c.06-.21-.17-.32-.48-.22-2 .72-35.91 22.16-39.66 24.53a1.87 1.87 0 0 1-.83.04Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>