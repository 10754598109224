function scrollToTop(id) {
  const el = document.querySelector(id);
  if (el) el.scrollTo(0,0);
}

export default function (to, from, savedPosition) {
  scrollToTop('#__layout')
  scrollToTop('#__layout > div')
  scrollToTop('#__body')
  scrollToTop('[aria-label="container"]')
  return { x: 0, y: 0 }
}