<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6">
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 137" fill="none" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 288">
      <g data-name="Group 236" clip-path="url(#a)">
        <path
          data-name="Path 209"
          d="M8.47 3.59a4.73 4.73 0 0 0-4.73 4.72v7.03a4.73 4.73 0 0 0 4.73 4.72h7.03a4.73 4.73 0 0 0 4.72-4.72V8.31A4.73 4.73 0 0 0 15.5 3.6Zm7.03 17.92H8.47a6.17 6.17 0 0 1-6.17-6.17V8.31a6.17 6.17 0 0 1 6.17-6.16h7.03a6.17 6.17 0 0 1 6.16 6.16v7.03a6.17 6.17 0 0 1-6.16 6.17"
          fill="#333"
        />
        <path
          data-name="Path 210"
          d="M11.98 7.05a2.23 2.23 0 1 0 2.24 2.23 2.24 2.24 0 0 0-2.24-2.23m0 5.9a3.67 3.67 0 1 1 3.68-3.67 3.68 3.68 0 0 1-3.68 3.67"
          fill="#333"
        />
        <path
          data-name="Path 211"
          d="M16.01 17.82H7.95a.87.87 0 0 1-.86-.87v-1.2a.87.87 0 0 1 .86-.86h8.06a.87.87 0 0 1 .87.86v1.2a.86.86 0 0 1-.87.87"
          fill="#f55c04"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>