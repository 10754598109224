<template>
  <component :is="icon"/>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      return {
        icon: require(`@/components/icons/${props.icon}.vue`).default,
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>