<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="23.749"
    viewBox="0 0 24 23.749"
    class="w-6 h-6 fill-current"
  >
    <g data-name="Group 345">
      <path
        data-name="Path 119"
        d="M17.06 4.432a1.781 1.781 0 0 0-.14-.253L14.699.814a1.813 1.813 0 0 0-2.668-.4l-3.8 3.149a1.8 1.8 0 0 0-.58.865Z"
        fill="#f55c04"
      />
      <path
        data-name="Path 120"
        d="M22.331 15.359a.676.676 0 0 1-.674.676h-4.661a2.361 2.361 0 0 1 0-4.723h4.661a.675.675 0 0 1 .674.674Zm-7.864 6.72H7.599a5.83 5.83 0 0 1-5.93-5.711v-5.391a5.83 5.83 0 0 1 5.93-5.711h6.868a5.786 5.786 0 0 1 5.8 4.377h-3.268a4.03 4.03 0 1 0 0 8.059h3.236a5.923 5.923 0 0 1-5.764 4.377m7.5-12.413a7.415 7.415 0 0 0-7.5-6.067H7.603a7.5 7.5 0 0 0-7.6 7.38v5.39a7.5 7.5 0 0 0 7.6 7.38h6.868a7.585 7.585 0 0 0 7.477-6.064 2.345 2.345 0 0 0 2.056-2.324v-3.375a2.345 2.345 0 0 0-2.033-2.32"
        fill="#333"
      />
      <path
        data-name="Path 121"
        d="M16.859 12.785a.887.887 0 1 0 .887.887.887.887 0 0 0-.887-.887"
        fill="#333"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>