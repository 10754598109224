<template>
  <div>
    <div class=" absolute bottom-24 inset-x-0 px-2 max-w-screen-sm m-auto z-10">
      <div
        class="
          border-2 border-bbmprimary
          rounded-full
          bg-white
          p-1
          flex
          items-center
          text-sm
        "
      >
        <p class="flex-grow pl-4 text-bbmprimary font-bold tracking-widest">
          成為會員每天驚喜不斷！
        </p>
        <button
          class="btn bg-bbmprimary text-white text-sm rounded-full"
          type="button"
          @click="show"
        >
          註冊 / 登入
        </button>
      </div>
    </div>

    <DialogLayout class="z-40" v-if="shwoform" ref="parent" @close="afterLeave">
      <AuthDialog @close="close" />
    </DialogLayout>
  </div>
</template>

<script>
import AuthDialog from "@/components/bbm/AuthDialog.vue";
import DialogLayout from "@/components/dating/DialogLayout";
export default {
  components: {
    DialogLayout,
    AuthDialog,
  },
  data() {
    return {
      shwoform: false,
    };
  },
  methods: {
    show() {
      this.shwoform = true;
    },
    close() {
      this.$refs.parent.close();
    },
    afterLeave() {
      this.shwoform = false;
    },
  },

  created() {
    this.$nuxt.$on("auth", this.show);
  },
  beforeDestroy() {
    this.$nuxt.$off("auth");
  },

  mounted() {
    this.show();
  },
};
</script>

<style lang="scss" scoped>
</style>