<template>
  <div
    class="h-full max-w-screen-sm mx-auto relative flex flex-col items-stretch overflow-hidden sm:overflow-y-scroll sm:scrollbar-hide sm:min-h-max"
  >
    <div class="flex-grow overflow-hidden flex flex-col items-stretch">
      <div class="overflow-y-scroll scrollbar-hide h-full" id="__body">
        <nuxt />
      </div>
    </div>
    <div class="flex-initial border-t border-floral-primary bg-white">
      <ButtonBar />
    </div>
  </div>
</template>

<script>
import ButtonBar from "@/components/cakeshop/navigation/ButtonBar.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ButtonBar,
  },
  middleware: ["cakeshop"],
  auth: false,
  head({}) {
    const shop = this.shop;

    const title = shop.name;
    const content = shop.about;

    return {
      title,
      meta: [
        { hid: "title", name: "title", content: title },
        { hid: "description", name: "description", content },

        {
          property: "og:title",
          name: "og:title",
          hid: "og:title",
          content: title,
        },
        {
          property: "og:site_name",
          name: "og:site_name",
          hid: "og:site_name",
          content: title,
        },
        {
          property: "og:description",
          name: "og:description",
          hid: "og:description",
          content,
        },
        {
          property: "og:url",
          name: "og:url",
          hid: "og:url",
          content: `${process.env.APP_URL}/s/${shop.domain}`,
        },
        {
          property: "og:image",
          name: "og:image",
          hid: "og:image",
          content: shop.logo,
        },
      ],
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href: shop.logo,
        },
      ],
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAP_STATIC_API_KEY}&libraries=places&language=${process.env.GOOGLE_API_LAN}&region=${process.env.GOOGLE_API_REGION}`,
        },
      ],
    };
  },
  

  computed: {
    ...mapGetters({
      shop: "cakeshop/shop/info",
      colors: "cakeshop/shop/colors",
    }),
  },
  beforeMount() {
    const { primary, secondary, text } = this.colors;
    document.documentElement.style.setProperty("--floral-primary-color", primary);
    document.documentElement.style.setProperty("--floral-secondary-color", secondary);
    document.documentElement.style.setProperty("--floral-text-color", text);
  },
  
};
</script>

<style lang="scss" scoped></style>
