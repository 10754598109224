export const state = () => ({
  constellations: [],
  locations: [],
  schools: [],
  ages: [],
  socials: [],
  times: [],
  options: [],
  addresses: [],
  sexuals: [],
  sex: {},
  members: {},
  info: {},
  special_event: false,
  angelsoldout: true,
  special: {},
  title: 'BoxBoxMo 澳門戀愛交友盲盒',
  about: `這是一個盲盒交友遊戲，你不知道會抽到誰的盲盒，也不知道你的盲盒將會被誰抽到。

如果你放入盲盒，建議你認真填寫，并留下真實有效的聯絡方式，以便喜歡你的人能聯絡到你。建議不要填寫手機號碼。

希望你帶著一顆真誠的心來交友，亂填盲盒有可能會作廢哦，為了讓交友訊息更加真實，抽放盲盒時需要支付費用，以及有審批系統，以提高參與門檻。

所有盲盒均來自網友，我們無法保證所有盲盒內容的真實性，在加好友時請一定要仔細甄別，請勿輕易轉賬以防被騙。本平台不承擔法律責任，虛擬商品概不退款。當你按下「我知道了」代表你已同意並遵守網站條款。

祝你能收獲一段美滿的戀情。`
})

export const getters = {
  constellations({ constellations }) {
    return constellations
  },
  sexuals({ sexuals }) {
    return sexuals
  },
  locations({ locations }) {
    return locations
  },
  schools({ schools }) {
    return schools
  },
  ages({ ages }) {
    return ages
  },
  socials({ socials }) {
    return socials
  },
  times({ times }) {
    return times
  },
  members({ members }) {
    return members
  },
  info({ info }) {
    return info
  },
  options({ options }) {
    return options
  },
  title({ title }) {
    return title
  },
  about({ about }) {
    return about
  },
  addresses({ addresses }) {
    return addresses
  },
  special_event({ special_event }) {
    return special_event
  },
  special({ special }) {
    return special
  },
  angelsoldout({ angelsoldout }) {
    return angelsoldout
  },
  sex({ sex }) {
    return sex
  },

  putbox({ putbox }) {
    return putbox
  },

  drawbox({ drawbox }) {
    return drawbox
  },
}

export const mutations = {
  SET_DATA(state, data) {
    const { location, constellation, school, age, social, times, members, info, options, addresses, special_event, angelsoldout, sexual, sex, putbox
      , drawbox } = data
    state.locations = location
    state.constellations = constellation
    state.sexuals = sexual
    state.schools = school
    state.ages = age
    state.socials = social
    state.times = times
    state.members = members
    state.info = info
    state.options = options
    state.addresses = addresses
    state.special_event = special_event
    state.angelsoldout = angelsoldout
    state.sex = sex
    state.putbox = putbox
    state.drawbox = drawbox
  },

  SET_SPECIAL(state, data) {
    state.special = data
  },

  IN_APP(state) {
    state.in_app = true
  }

}

export const actions = {
  init({ commit }, query) {
    return this.$axios.get('/dating/options', { params: query })
      .then(({ data }) => {
        commit('SET_DATA', data.data)
      })
  },

  specialbox({ commit }) {
    return this.$axios.get('/dating/options/specialbox')
      .then(({ data }) => {
        commit('SET_SPECIAL', data.data)
      })
  },



}
