<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6 fill-current">
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 121" fill="none" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 205">
      <g data-name="Group 204" clip-path="url(#a)">
        <path
          data-name="Path 180"
          d="M13.27 12.203a3.661 3.661 0 1 0 3.661-3.661 3.662 3.662 0 0 0-3.661 3.661"
          fill="#f55c04"
        />
        <path
          data-name="Path 181"
          d="M5.355 7.039a1.243 1.243 0 0 0-1.274 1.206v7.612a1.243 1.243 0 0 0 1.274 1.206h2.377a2.751 2.751 0 0 1 1.463.418l4.331 2.7a1.314 1.314 0 0 0 1.348.035 1.17 1.17 0 0 0 .628-1.039V5.064a1.17 1.17 0 0 0-.628-1.039 1.317 1.317 0 0 0-1.347.032L9.404 6.623a2.765 2.765 0 0 1-1.461.417Zm8.872 14.783a2.764 2.764 0 0 1-1.463-.419l-4.331-2.7a1.309 1.309 0 0 0-.7-.2H5.355a2.684 2.684 0 0 1-2.714-2.646V8.245a2.684 2.684 0 0 1 2.714-2.646h2.587a1.31 1.31 0 0 0 .7-.2l4.122-2.565a2.768 2.768 0 0 1 2.807-.069 2.606 2.606 0 0 1 1.368 2.3v14.111a2.6 2.6 0 0 1-1.37 2.3 2.777 2.777 0 0 1-1.344.349"
          fill="#333"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>