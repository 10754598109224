<template>
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    class="w-6 h-6"
  >
    <defs>
      <clipPath id="a"><path style="fill: none" d="M0 0h30v30H0z" /></clipPath>
    </defs>
    <g data-name="Group 39">
      <g data-name="Group 38" style="clip-path: url(#a)">
        <path
          data-name="Path 27"
          d="M23.852 25.8H5.954a1.85 1.85 0 0 1-1.85-1.85v-.75a1.85 1.85 0 0 1 1.85-1.85h17.9A1.85 1.85 0 0 1 25.7 23.2v.754a1.848 1.848 0 0 1-1.846 1.85"
          style="fill: #f55c04"
        />
        <path
          data-name="Path 28"
          d="m23.475 9.275-1.134 1.878h-.007l-7.758-4.6 1.175-1.946a1.007 1.007 0 0 1 .859-.483 1.01 1.01 0 0 1 .514.143L23.138 7.9a1 1 0 0 1 .338 1.373m-7.26 12.017a1 1 0 0 1-.622.456l-5.3 1.269a1 1 0 0 1-1.2-.741.97.97 0 0 1-.015-.074l-.767-4.818a1 1 0 0 1 .133-.674l5.207-8.616L21.41 12.7Zm9.124-13.2a2.779 2.779 0 0 0-1.271-1.725l-6.016-3.64a2.8 2.8 0 0 0-3.843.948L6.9 15.777a2.8 2.8 0 0 0-.369 1.888l.762 4.817a2.8 2.8 0 0 0 2.768 2.359 2.893 2.893 0 0 0 .649-.077l5.3-1.269a2.814 2.814 0 0 0 1.744-1.274l7.26-12.015a2.774 2.774 0 0 0 .321-2.116"
          style="fill: #333"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>