import { map, filter, first } from 'lodash'
import moment from 'moment'



export const state = () => ({
  order: {
    items: [],
    count: 0,
    total: 0,
    amount: 0,
    discount: 0,
    details: {},
    pickup_methods: [],
    payment_methods: [],
    promocode_used_logs: [],
  },
  dates: [],
  time: []
})

export const getters = {
  order({ order }) {
    return order
  },
  items({ order }) {
    return order.items
  },
  promocodes({ order }) {
    return order.promocode_used_logs
  },
  amount({ order }) {
    return order.amount
  },
  discount({ order }) {
    return order.discount
  },
  total({ order }) {
    return order.total
  },
  count({ order }) {
    return order.count
  },

  currency({ order }) {
    return 'MOP'
  },

  rules({ order }) {
    return {
      start_time: order.start_time,
      start_date: order.start_date,
      last_date: order.last_date,
    }
  },
  dates({ dates, time, order }) {
    const time_format = 'HH:mm';
    const date_format = 'YYYY-MM-DD';
    const { start_date, last_date, start_time } = order

    const timelist = filter(time, t => {
      if (start_time)
        return moment(first(t), time_format).isSameOrAfter(moment(start_time, time_format))

      return true;
    })

    const datelist = filter(dates, d => {
      const date = moment(d.fulldate, date_format)
      if (start_date && last_date) return date.isSameOrAfter(start_date) && date.isSameOrBefore(last_date);

      if (start_date) return date.isSameOrAfter(start_date)

      if (last_date) return date.isSameOrBefore(last_date)

      return true
    })


    return map(datelist, d => {
      return {
        ...d, time: timelist
      }
    })
  },
  time({ time }) {
    return time
  },
  details({ order }) {
    return order.details
  },
  pickup_methods({ order }) {
    return order.pickup_methods
  },
  payment_methods({ order }) {
    return order.payment_methods
  }
}

export const mutations = {
  SET_DATA(state, data) {
    state.order = data
  },

  SET_DATES(state, data) {
    state.dates = data.dates
    state.time = data.time
  },

  RESET(state) {
    state.order = {
      items: [],
      count: 0,
      total: 0,
      amount: 0,
      discount: 0,
      details: {},
      pickup_methods: [],
      payment_methods: [],
      promocode_used_logs: [],
    }
  }
}

const _cookies_key = 'cakeshop.order'


export const actions = {
  // 獲取訂單
  async fetch({ commit }, { shop, order }) {
    const uuid = order || this.$cookies.get(_cookies_key);
    return this.$axios.get(`/shops/${shop}/orders/${uuid}`)
      .then(({ data }) => {
        commit('SET_DATA', data.data)
      })
  },

  // 取消優惠碼
  async cancelPromoCode({ dispatch },
    { shop, code }) {
    const order = this.$cookies.get(_cookies_key);
    if (!order) return;

    return this.$axios.delete(`/shops/${shop}/orders/${order}/promo/${code}`)
      .then(() => {
        return dispatch('fetch', { shop, order })
      })
  },

  // 獲取可下單日期
  async fetchDates({ commit }, { shop, order }) {
    const uuid = order || this.$cookies.get(_cookies_key);
    return this.$axios.get(`/shops/${shop}/dates`, {
      params: { order: uuid }
    })
      .then(({ data }) => {
        commit('SET_DATES', data.data)
      })
  },

  checkout({ }, { shop, formdata }) {
    const order = this.$cookies.get(_cookies_key);
    if (!order) return;

    return this.$axios.patch(`/shops/${shop}/orders/${order}`, formdata);
  },

  pay({ }, shop) {
    const order = this.$cookies.get(_cookies_key);
    if (!order) return;

    return this.$axios.post(`/shops/${shop}/orders/${order}/pay`);
  },

  usePromoCode({ dispatch }, { shop, code }) {
    const order = this.$cookies.get(_cookies_key);
    if (!order) return;

    return this.$axios.post(`/shops/${shop}/orders/${order}/promo`, {
      code
    })
  },

  updateItem({ dispatch }, { shop, item, quantity }) {
    const order = this.$cookies.get(_cookies_key);
    if (!order) return;

    return this.$axios.patch(`/shops/${shop}/orders/${order}`, {

      item, quantity

    }).then(({ data }) => {
      return dispatch('fetch', {
        shop, order
      })
    })
  },

  removeItem({ dispatch }, { shop, item }) {
    const order = this.$cookies.get(_cookies_key);
    if (!order) return;

    return this.$axios.delete(`/shops/${shop}/orders/${order}`, {
      params: { item }
    })
      .then(({ data }) => {
        return dispatch('fetch', {
          shop, order
        })
      })
  },

  removeItemAll({ dispatch }, shop) {
    return dispatch('removeItem', {
      shop, item: 'all'
    })
  },

  cleanOrder({ commit }) {
    this.$cookies.remove(_cookies_key);

    commit('RESET')
  }
}
