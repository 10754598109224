<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 26 31"
  >
    <defs>
      <linearGradient
        id="a"
        y1="15.5"
        x2="26"
        y2="15.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#1d93d2" />
        <stop offset="1" stop-color="#38b0e3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="8"
        y1="13.99"
        x2="18"
        y2="13.99"
        xlink:href="#a"
      />
    </defs>
    <g data-name="Layer 2">
      <g data-name="Icon">
        <path
          d="M24.06 1.87a8.27 8.27 0 0 1-5.66-.62 12.27 12.27 0 0 0-10.8 0 8.27 8.27 0 0 1-5.66.62h-.15A1.47 1.47 0 0 0 0 3.23V17c0 10.21 12.21 13.8 12.73 14H13l.27-.08C13.79 30.77 26 27.18 26 17V3.38a1.58 1.58 0 0 0-1.94-1.51ZM24 17c0 7.92-9.27 11.37-11 11.94-1.73-.57-11-4-11-11.94V4a10.25 10.25 0 0 0 6.49-1 10.28 10.28 0 0 1 9 0 10.19 10.19 0 0 0 4.56 1.08A9.64 9.64 0 0 0 24 3.93Z"
          fill="url(#a)"
        />
        <path
          d="M13 9a5 5 0 1 0 5 5 5 5 0 0 0-5-5Zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3Z"
          fill="url(#b)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>