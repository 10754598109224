export default function ({ $auth, route, router, redirect, next }) {
  if ($auth.loggedIn) {
    if (!$auth.user.username && route.name != 'home-signup') {
      console.log('undefind username and position not in home-signup');
      return redirect('/home/signup')
    }

    if ($auth.user.username && route.name == 'home-signup') {
      console.log('alreay signup');
      return redirect('/home')
    }
  }

  // next(route.path);
}
