<template>
  <div class="ui-app">
    <div class="min-h-full flex flex-col items-stretch">
      <vHeader
        class="flex-initial sticky top-0 inset-x-0 pt-safe z-10"
        v-if="showHeader()"
      />

      <div class="flex-grow h-full flex flex-col">
        <nuxt />
      </div>

      <vFooter class="flex-initial pb-safe" />
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/Header";
export default {
  components: {
    vHeader,
  },

  methods: {
    showHeader() {
      const { name } = this.$route;
      return !(name ?? "").includes("id");
    },
  },
};
</script>
