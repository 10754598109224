<template>
  <ul aria-label="navs" class="text-sm tracking-wider">
    <li v-if="preve.length">
      <a
        href="#"
        class="px-2 py-3 flex justify-between items-center"
        @click.prevent="select(category)"
      >
        <ChevronLeftIcon class="flex-none w-4 h-4" />
        <span class="overflow-x-scroll scrollbar-hide font-medium">{{
          category.name
        }}</span>
      </a>
    </li>
    <li v-for="item in categories" :key="item.id" :area-item="item.name">
      <template v-if="item.children.length">
        <!-- 展開 -->
        <a
          href="#"
          class="px-2 py-3 flex justify-between items-center"
          @click.prevent="select(item)"
        >
          <span class="overflow-x-scroll scrollbar-hide">{{ item.name }}</span>
          <ChevronRightIcon class="flex-none w-4 h-4"
        /></a>
      </template>

      <template v-else>
        <a
          class="px-2 py-3 block overflow-x-scroll scrollbar-hide"
          :class="[
            {
              'bg-floral-primary font-medium text-floral-text': isActive(item),
            },
          ]"
          @click="select(item)"
        >
          {{ item.name }}
        </a>
      </template>
    </li>
    <slot />
  </ul>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@vue-hero-icons/outline";
import { last } from "lodash";

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    current: Object,
    preve: Array,
  },
  data() {
    return {};
  },
  methods: {
    isActive(category) {
      return this.current.id === category.id;
    },

    select(category) {
      this.$emit("select", category);
    },
  },
  computed: {
    category() {
      return last(this.preve) || {};
    },
  },
};
</script>

<style lang="scss" scoped></style>
