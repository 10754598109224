<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
  >
    <defs>
      <clipPath id="clip-path">
        <path style="fill: none" d="M0 0h30v30H0z" />
      </clipPath>
      <style>
        .cls-4 {
          fill: #333;
        }
      </style>
    </defs>
    <g style="clip-path: url(#clip-path)" id="Group_35" data-name="Group 35">
      <g id="Group_34" data-name="Group 34">
        <path
          id="Path_19"
          data-name="Path 19"
          d="m18.341 21.282-6.673-.018c-1.1 0-1.714 1.086-1.013 1.81L14 26.53a1.463 1.463 0 0 0 2.018 0l3.331-3.439c.7-.72.1-1.807-1-1.81"
          style="fill: #f55c04"
        />
        <path
          id="Path_20"
          data-name="Path 20"
          class="cls-4"
          d="M9.986 5.679a5.738 5.738 0 0 0-5.734 5.732v5.666a5.737 5.737 0 0 0 5.727 5.732h9.941a5.736 5.736 0 0 0 5.731-5.731v-5.667A5.736 5.736 0 0 0 19.92 5.68Zm9.941 18.93H9.986a7.539 7.539 0 0 1-7.532-7.532v-5.666a7.539 7.539 0 0 1 7.532-7.532h9.941a7.539 7.539 0 0 1 7.532 7.532v5.666a7.538 7.538 0 0 1-7.532 7.532"
        />
        <path
          id="Path_21"
          data-name="Path 21"
          class="cls-4"
          d="M13.739 14.244a1.262 1.262 0 1 0 0-.006v.006"
        />
        <path
          id="Path_22"
          data-name="Path 22"
          class="cls-4"
          d="M7.893 14.244a1.262 1.262 0 1 0 1.261-1.266 1.262 1.262 0 0 0-1.261 1.266"
        />
        <path
          id="Path_23"
          data-name="Path 23"
          class="cls-4"
          d="M19.584 14.244a1.261 1.261 0 1 0 1.261-1.265 1.262 1.262 0 0 0-1.261 1.265"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>