<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.42 40.59">
    <g data-name="Layer 2">
      <path
        d="M21.71 25.53A12.77 12.77 0 1 0 9 12.77a12.75 12.75 0 0 0 12.71 12.76Zm0-21.75a9 9 0 1 1-8.93 9 9 9 0 0 1 8.93-9Zm5.89 23a1.89 1.89 0 0 0-1.8.36l-4.09 3.48-4.08-3.48a1.9 1.9 0 0 0-1.8-.36C13.19 27.62 0 32.16 0 38.7a1.89 1.89 0 0 0 1.89 1.89h39.65a1.88 1.88 0 0 0 1.88-1.89c0-6.54-13.18-11.08-15.82-11.92Zm-22.83 10c2.08-2.21 7.06-4.66 11.22-6.11l4.5 3.82a1.89 1.89 0 0 0 2.45 0l4.49-3.82c4.16 1.45 9.14 3.9 11.23 6.11Z"
        data-name="Layer 1"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>