import Vue from 'vue'


import VueSanitize from "vue-sanitize";
Vue.use(VueSanitize, {
  allowedTags: ['a'],
  allowedAttributes: {
    'a': ['href', 'class', 'target']
  },
  allowedSchemes: ['https']
});

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

import { Popup, Dialog, Toast, List, DatetimePicker,Popover } from "vant";

Vue.use(Popup);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(DatetimePicker);
Vue.use(List);
Vue.use(Popover);



import MyshopFooterText from '@/components/myshop/FooterText'
Vue.component('MyshopFooterText', MyshopFooterText);

import SearchForm from '@/components/Form/SearchForm';
Vue.component('SearchForm', SearchForm);

import Pagination from "@luminexs/components/navigation/Pagination.vue";
import vTable from "@luminexs/components/list/Table.vue";
import vColumn from "@luminexs/components/list/Column.vue";
import Modal from "@luminexs/components/modal/Modal.vue";


Vue.component('Pagination', Pagination);
Vue.component('vTable', vTable);
Vue.component('vColumn', vColumn);
Vue.component('Modal', Modal);



import Textarea2 from '@luminexs/components/form/Textarea2.vue'
Vue.component('Textarea2', Textarea2)

import Field from '@luminexs/components/form/Field.vue'
Vue.component('vField', Field)

import FieldWithLabel from '@luminexs/components/form/FieldWithLabel.vue'
Vue.component('FieldWithLabel', FieldWithLabel)

// import FieldWithLabel2 from '@luminexs/components/form/FieldWithLabel2.vue'
// Vue.component('FieldWithLabel2', FieldWithLabel2)

import vFooter from '@/components/Footer'
Vue.component('vFooter', vFooter)



import MyIcon from '@/components/MyIcon'
Vue.component('MyIcon', MyIcon)

import Icon from '@/components/icons/Icon'
Vue.component('Icon', Icon)

import Loading from '@/components/Loading'
Vue.component('Loading', Loading)



import DatePicker from 'vue2-datepicker';

Vue.component('DatePicker', DatePicker)



// import LoginButton from '@/components/FB/LoginButton'
// Vue.component('LoginButton', LoginButton)


import { useWindowSize } from '@vueuse/core'


if (process.client) {
  window.objectToFormData = require('object-to-formdata').serialize

  window.fbAsyncInit = function () {
    FB.init({
      appId: process.env.FACEBOOK_APP_ID,
      cookie: true,
      xfbml: true,
      version: process.env.FACEBOOK_VERSION
    });

    FB.AppEvents.logPageView();

  };

  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/zh_HK/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));



  $(document).ready(function () {
    $.ajaxSetup({ cache: true })

    // const layout = document.body


    // const setHeight = () => {
    // //   console.log('layout resize');
    //   const { width, height } = useElementSize(layout)
    //   console.log('layout resize', width, height);
    //   document.documentElement.style.setProperty("--app-height", height.value + 'px');
    // }



    // // when layout resize
    // layout.addEventListener('resize', setHeight)


    // setHeight()




    // const appHeight = window.appHeight = () => {
    //   // delay 300ms to call appHeight

    //   setTimeout(function () {
    //     // set app height
    //     const _height = window.visualViewport.height + 'px'
    //     document.documentElement.style.setProperty("--app-height", _height);
    //     // console.log('appHeight', _height);
    //   }, 300);

    // };

    // // when resize , appHeight will be called
    // window.addEventListener("resize", appHeight);



    // // when page load , appHeight will be called
    // appHeight()
  })

  // when mounted
}

function setWindowHeight() {
  const { height } = useWindowSize()
  document.documentElement.style.setProperty("--app-height", height.value + 'px');
}

export default ({ app }, inject) => {
  // client side
  if (process.client) {
    window.addEventListener("resize", setWindowHeight);
    setWindowHeight()
  }

  
}