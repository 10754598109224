import Vue from 'vue';

import ShareDialog from '@/components/modal/ShareDialog.vue';

const Share = Vue.extend(ShareDialog);

Share.install = (Vue, options) => {
  Vue.prototype.$share = new Share({ propsData: options });
}

Vue.use(Share, {});

