<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 26 24"
  >
    <defs>
      <linearGradient
        id="a"
        y1="8"
        x2="26"
        y2="8"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#1d93d2" />
        <stop offset="1" stop-color="#38b0e3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="6.29"
        y1="14.79"
        x2="19.71"
        y2="14.79"
        xlink:href="#a"
      />
    </defs>
    <g data-name="Layer 2">
      <g data-name="Icon">
        <path
          d="M2 16V3a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v13h2V3a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3v13Z"
          fill="url(#a)"
        />
        <path
          fill="url(#b)"
          d="m6.29 12.29 1.42 1.42L12 9.41V24h2V9.41l4.29 4.3 1.42-1.42L13 5.59l-6.71 6.7z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>