<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 30">
    <defs>
      <linearGradient
        id="a"
        y1="15"
        x2="25"
        y2="15"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#1d93d2" />
        <stop offset="1" stop-color="#38b0e3" />
      </linearGradient>
    </defs>
    <g data-name="Layer 2">
      <path
        d="M12.2 30a2 2 0 0 1-2-2.15l.67-9.38H2a2 2 0 0 1-1.8-1.13 2 2 0 0 1 .23-2.12L11.79.73a2.06 2.06 0 0 1 3.1-.06 2 2 0 0 1 .45 1.61l-1.25 8.15H23a2 2 0 0 1 1.64 3.16L13.82 29.14a2 2 0 0 1-1.62.86Zm0-2Zm1.16-26L2 16.46h11L12.19 28 23 12.44H11.76Z"
        fill="url(#a)"
        data-name="Icon"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>