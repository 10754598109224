<template>
  <van-popup
    v-model="show"
    :position="popupPosition()"
    @closed="closed"
    round
    class="max-w-screen-sm w-full"
  >
    <ShareListCard
      :sharedata="sharedata"
      :title="title"
      :textclass="textclass"
      :titleclass="titleclass"
      :bgclass="bgclass"
      :items="items"
      :sharetext="sharetext"
    />
  </van-popup>
</template>

<script>

import ShareListCard from "@/components/card/ShareListCard.vue";
export default {
  components: {
    ShareListCard,
  },
  // props: {
  //   sharedata: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  data() {
    return {
      show: false,
      sharedata: {},
      textclass: "text-floral-text",
      titleclass: "text-floral-primary",
      bgclass: "bg-floral-primary",
      sharetext: "",
      items: [],
    };
  },
  methods: {
    async share(sharedata, title, options = {}) {
      this.sharedata = sharedata;
      this.title = title;

      Object.keys(options).forEach((key) => {
        this[key] = options[key];
      });

      if (!this.$parent) {
        this.$mount();
        document.body.appendChild(this.$el);
        this.show = true;
      }
    },
    closeDialog() {
      this.show = false;
    },
    closed() {
      document.body.removeChild(this.$el);
    },
  },
};
</script>

<style lang="scss" scoped></style>
