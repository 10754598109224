<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 28 32"
  >
    <defs>
      <linearGradient
        id="a"
        y1="16"
        x2="28"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#1d93d2" />
        <stop offset="1" stop-color="#38b0e3" />
      </linearGradient>
      <linearGradient id="b" x1="11" y1="20" x2="17" y2="20" xlink:href="#a" />
    </defs>
    <g data-name="Layer 2">
      <path
        d="M26 9h-3V3a3 3 0 0 0-3-3H8a3 3 0 0 0-3 3v6H2a2 2 0 0 0-2 2v13a8 8 0 0 0 8 8h12a8 8 0 0 0 8-8V11a2 2 0 0 0-2-2ZM7 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6H7Zm19 21a6 6 0 0 1-6 6H8a6 6 0 0 1-6-6V11h24Z"
        fill="url(#a)"
      />
      <path
        d="M14 15a3 3 0 0 0-1 5.82V25h2v-4.18A3 3 0 0 0 14 15Z"
        fill="url(#b)"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>