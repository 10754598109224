<template>
  <div class="max-h-500 flex flex-col">
    <div class="flex-grow overflow-scroll px-4">
      <div class="flex items-center justify-center md:px-10 mt-4">
        <div class="flex-none mr-5">
          <img class="md:w-10 md:h-10 w-12 h-12" src="/social/fb.png" alt="" />
        </div>
        <div class="flex-grow">
          <p>BoxBoxMo</p>
          <button
            type="button"
            @click="_goSocial(boxbox.fb)"
            class="block text-left"
            v-text="boxbox.fb"
          />
        </div>
      </div>

      <div class="flex items-center justify-center md:px-10 mt-8">
        <div class="flex-none mr-5">
          <img class="md:w-10 md:h-10 w-12 h-12" src="/social/ig.png" alt="" />
        </div>
        <div class="flex-grow">
          <p>BoxBoxMo</p>
          <button
            type="button"
            @click="_goSocial(boxbox.ig)"
            class="block text-left"
            v-text="boxbox.ig"
          />
        </div>
      </div>

      <div class="h-10"></div>
    </div>

    <div class="flex-none bg-white px-6 pb-6">
      <button
        type="button"
        @click="$emit('close')"
        class="
          font-bold
          text-sm
          py-4
          bg-black
          rounded-lg
          text-white
          w-full
          mt-4
        "
      >
        我知道了
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      info: "dating/info",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>