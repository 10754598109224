<template>
  <form class="max-h-500 flex flex-col" action="#" @submit.prevent="submit">
    <div class="flex-grow overflow-scroll pt-4 px-4">
      <div class="flex space-x-4">
        <p class="flex-none text-lg text-gray-800 mt-2 w-1/4 text-center">+853</p>
        <FieldWithLabel
          id="tel"
          v-model="form['tel']"
          placeholder="電話號碼"
          :disabled="submitting"
          class="flex-grow"
        />
      </div>

      <FieldWithLabel
        id="password"
        type="password"
        v-model="form['password']"
        placeholder="登入密碼"
        :disabled="submitting"
      />

      <div class="mb-4">
        <div class="flex justify-center">
          <div>
            <button
              type="button"
              class="text-bbmprimary font-medium"
              @click="signup"
            >
              會員註冊
            </button>
          </div>
          <div class="px-4 text-gray-300">/</div>
          <div>
            <button type="button" class="text-bbmprimary font-medium" @click="forgot">
              忘記密碼
            </button>
          </div>
        </div>
      </div>

      <div class="h-10"></div>
    </div>
    <div class="flex-none bg-white pb-6 px-4">
      <button type="submit" class="btn w-full bbm mb-4" :disabled="submitting">
        登入
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      submitting: false,
    };
  },
  methods: {
    async submit() {
      if (this.submitting) return;
      try {
        this.submitting = true;
        await this.verify();
        this.$auth.options.redirect = this.$route.path;
        await this.$auth.loginWith("bbm", { data: this.form });
        this.saveAppToken();
        this.fetchWallet();
        this.clearCookies();
        this.$emit("close");
        this.$router.push(this.localePath(this.$route.path));
      } catch (e) {
        console.error(e);
      } finally {
        this.submitting = false;
      }
    },
    signup() {
      this.$emit("changeto", "signup");
    },
    forgot() {
      this.$emit("changeto", "forgot");
    },
  },
  beforeDestroy() {
    this.clearErrors();
  },
};
</script>

<style lang="scss" scoped></style>
