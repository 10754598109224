<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6">
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 95" fill="none" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 354">
      <g data-name="Group 152" clip-path="url(#a)">
        <path
          data-name="Path 136"
          d="M9.059 3.378a4.666 4.666 0 0 0-4.66 4.66v8.026a4.665 4.665 0 0 0 4.66 4.66h6.148a4.665 4.665 0 0 0 4.66-4.66V8.04a4.666 4.666 0 0 0-4.66-4.66H9.059Zm6.146 18.788H9.059a6.107 6.107 0 0 1-6.1-6.1V8.039a6.107 6.107 0 0 1 6.1-6.1h6.148a6.107 6.107 0 0 1 6.1 6.1v8.026a6.108 6.108 0 0 1-6.1 6.1"
          fill="#333"
        />
        <path
          data-name="Path 137"
          d="M9.708 6.488A1.684 1.684 0 0 0 8.026 8.17v5.63a1.684 1.684 0 0 0 1.682 1.681h4.849a1.683 1.683 0 0 0 1.681-1.681V8.17a1.684 1.684 0 0 0-1.681-1.682Zm4.849 10.433H9.708A3.125 3.125 0 0 1 6.587 13.8V8.17a3.126 3.126 0 0 1 3.121-3.122h4.849a3.126 3.126 0 0 1 3.121 3.122v5.63a3.125 3.125 0 0 1-3.121 3.121"
          fill="#333"
        />
        <path
          data-name="Path 138"
          d="M14.098 20.123h-3.934a1.043 1.043 0 0 1-1.039-1.043v-.524a1.043 1.043 0 0 1 1.042-1.042h3.934a1.042 1.042 0 0 1 1.042 1.042v.524a1.042 1.042 0 0 1-1.042 1.043"
          fill="#5ca9f7"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>