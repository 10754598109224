<template>
  <div
    class="
      bg-repeat bg-center bg-contain
      overflow-hidden
      max-h-app
      relative
      bg-[#f3f7f9]
    "
    :style="`background-image: url(${bgimg});`"
  >
    <div class="max-w-screen-sm mx-auto h-full overflow-hidden">
      <div
        class="
          flex flex-col
          h-screen-ios h-screen
          max-app-h
          overflow-hidden
          items-stretch
        "
      >
        <div class="flex-none">
          <HeaderNavigation />
        </div>

        <div class="flex-grow overflow-hidden flex flex-col items-stretch">
          <div class="overflow-y-scroll scrollbar-hide h-full" id="__body">
            <nuxt />
            <dating-footertext />
            <div class="h-32"></div>
          </div>
        </div>

        <div class="flex-none z-10 bg-white">
          <BottomNavigation />
        </div>
      </div>
    </div>

    <client-only>
      <Auth v-if="!loggedin" />
      <vDialog
        :title="step.title"
        v-if="show_dialog"
        @close="closeDialog"
        ref="maindialog"
        class="z-50"
      >
        <WebRuleBox v-if="step.type == 'webterms'" @close="close" />
        <UserRuleBox v-if="step.type == 'userterms'" @close="close" />
        <ContactUsBox v-if="step.type == 'contactus'" @close="close" />
      </vDialog>
    </client-only>
  </div>
</template>

<script>
import DatingFootertext from "@/components/dating/footertext";
import vDialog from "@/components/dating/Dialog";
import WebRuleBox from "@/components/dating/WebRuleBox.vue";
import UserRuleBox from "@/components/dating/UserRuleBox.vue";
import ContactUsBox from "@/components/dating/ContactUsBox.vue";

import HeaderNavigation from "@/components/bbm/HeaderNavigation";
import BottomNavigation from "@/components/bbm/BottomNavigation";
import Auth from "@/components/bbm/Auth";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    DatingFootertext,
    Auth,
    HeaderNavigation,
    BottomNavigation,
    vDialog,
    WebRuleBox,
    UserRuleBox,
    ContactUsBox,
  },
  head() {
    const title = "BoxBoxMo 澳門戀愛交友盲盒";
    const content = this.about;

    return {
      title,
      meta: [
        { hid: "title", name: "title", content: title },
        { hid: "description", name: "description", content },

        {
          property: "og:title",
          name: "og:title",
          hid: "og:title",
          content: title,
        },

        {
          property: "og:site_name",
          name: "og:site_name",
          hid: "og:site_name",
          content: title,
        },
        {
          property: "og:description",
          name: "og:description",
          hid: "og:description",
          content,
        },
        {
          property: "og:url",
          name: "og:url",
          hid: "og:url",
          content: `${process.env.APP_URL}/boxboxmo`,
        },
        {
          property: "og:image",
          name: "og:image",
          hid: "og:image",
          content: `https://imgs.iclover.net/unknoown/base/dating/logo2.png`,
        },
      ],
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href: "https://imgs.iclover.net/unknoown/base/dating/icon.png",
        },
      ],
    };
  },

  data() {
    return {
      cookiekey: "boxboxmo-userterm",
    };
  },
  methods: {
    ...mapActions({
      closeDialog: "dialog/close",
    }),
    close() {
      this.$refs.maindialog.close();
    },

    setInvitedBy() {
      const { invitedby } = this.$route.query;
      if (!invitedby) return;
      this.$cookies.set("invitedby", invitedby);
    },
  },
  computed: {
    ...mapGetters({
      info: "dating/info",
      about: "dating/about",
      show_dialog: "dialog/show",
      step: "dialog/step",
    }),
    bgimg() {
      if (this.$route.name == "boxboxmo-lgbt")
        return "https://imgs.iclover.net/unknoown/base/dating/bglgbt.jpg";

      if (
        this.$route.name.includes("records") ||
        this.$route.name.includes("me") ||
        this.$route.name.includes("mplay")
      )
        return "";

      return "https://imgs.iclover.net/unknoown/base/dating/bg.png";
    },
  },
  mounted() {
    this.setInvitedBy();
    if (this.loggedin) this.fetchWallet();
  },
};
</script>

<style lang="scss" scoped>
</style>