<template>
  <div class="ui-app">
    <div class="h-screen max-h-app flex flex-col items-stretch max-w-screen-sm mx-auto">
      <div class="flex-grow overflow-hidden">
        <nuxt />
        <!-- 聯絡客戶 -->
        <CustomerServiceCard />
        <!-- <div  aria-label="container">
        </div> -->
      </div>
      <div class="flex-shrink bg-f8">
        <ButtonBar />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBar from "@/components/cakeshop/navigation/ButtonBar.vue";

import CustomerServiceCard from "@/components/card/CustomerServiceCard.vue";
import CatelogNavigation from "@/components/cakeshop/product/CatelogNavigation.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    ButtonBar,
    CustomerServiceCard,
    CatelogNavigation,
  },
  auth: false,
  head({}) {
    // generate style script
    const shop = this.shop;

    const title = shop.name;
    const content = shop.about;

    return {
      title,
      meta: [
        { hid: "title", name: "title", content: title },
        { hid: "description", name: "description", content },
        // add custom style to head
        {
          property: "og:title",
          name: "og:title",
          hid: "og:title",
          content: title,
        },
        {
          property: "og:site_name",
          name: "og:site_name",
          hid: "og:site_name",
          content: title,
        },
        {
          property: "og:description",
          name: "og:description",
          hid: "og:description",
          content,
        },
        {
          property: "og:url",
          name: "og:url",
          hid: "og:url",
          content: `${process.env.APP_URL}/s/${shop.domain}`,
        },
        {
          property: "og:image",
          name: "og:image",
          hid: "og:image",
          content: shop.logo,
        },
        {
          hid: "application-name",
          name: "application-name",
          content: title,
        },
        {
          name: "apple-mobile-web-app-title",
          content: title,
        },
        {
          name: "apple-mobile-web-app-capable",
          content: "yes",
        },
        {
          name: "mobile-web-app-capable",
          content: "yes",
        },
        {
          name: "apple-mobile-web-app-status-bar-style",
          content: "black",
        },
        {
          name: "viewport",
          content:
            "width=device-width,initial-scale=1.0,minimum-scale=1.0,shrink-to-fit=no",
        },
        {
          name: "format-detection",
          content: "telephone=no",
        },
        {
          name: "format-detection",
          content: "address=no",
        },
        {
          name: "color-scheme",
          content: "drak",
        },
        {
          name: "referrer",
          content: "origin",
        },
      ],
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAP_STATIC_API_KEY}&libraries=places&language=${process.env.GOOGLE_API_LAN}&region=${process.env.GOOGLE_API_REGION}`,
        },
      ],
      link: [
        {
          rel: "stylesheet",
          href: shop.theme,
          sync: true,
          defer: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      shop: "cakeshop/shop/info",
      colors: "cakeshop/shop/colors",
    }),
  },
};
</script>

<style lang="css">
/* .ui-app {
  
} */


</style>
