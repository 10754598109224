<template>
  <DialogLayout ref="parent"  @close="afterLeave">
    <div class="flex flex-col h-full  overflow-hidden">

      <div class="bg-white px-4 pt-6 pb-4 flex-none">
        <button type="button" class="absolute left-4 top-6" @click="close">
          <XIcon class="w-6 h-6" v-if="!prestep" />
          <ChevronLeftIcon class="w-6 h-6" v-if="prestep" />
        </button>
        <p class="text-sm text-center">{{ title }}</p>
        <button type="button" class="absolute right-4 top-6" @click="info" v-if="!prestep && info">
          <InformationCircleIcon class="w-6 h-6"  />
        </button>
      </div>

      <div class="flex-grow flex flex-col overflow-hidden items-stretch max-h-500">
        <slot />
      </div>

    </div>
  </DialogLayout>
</template>

<script>
import { XIcon, ChevronLeftIcon,InformationCircleIcon } from "@vue-hero-icons/outline";
import DialogLayout from "./DialogLayout";
export default {
  components: {
    XIcon,
    ChevronLeftIcon,
    DialogLayout,
    InformationCircleIcon
  },
  props: {
    title: {
      type: String,
      default: "dialog title",
    },
    prestep: Object, //上一步
    info: Function // 說明
  },
  
  methods: {
    show() {
      this.$refs.parent.show();
    },
    close() {
      if (this.prestep) {
        return this.$emit("close");
      }
      this.$refs.parent.close();
    },
    afterLeave() {
      this.$emit("close");
    },
  },
  mounted() {
    this.show();
  },
};
</script>

<style lang="scss" scoped>
</style>