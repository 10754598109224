<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    viewBox="0 0 100 100"
    class="fill-current"
    :class="defaultClass"
  >
    <defs />
    <g transform="translate(20 50)">
      <circle r="6">
        <animateTransform
          attributeName="transform"
          begin="-0.375s"
          calcMode="spline"
          dur="1s"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="scale"
          values="0;1;0"
        />
      </circle>
    </g>
    <g transform="translate(40 50)">
      <circle r="6">
        <animateTransform
          attributeName="transform"
          begin="-0.25s"
          calcMode="spline"
          dur="1s"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="scale"
          values="0;1;0"
        />
      </circle>
    </g>
    <g transform="translate(60 50)">
      <circle r="6">
        <animateTransform
          attributeName="transform"
          begin="-0.125s"
          calcMode="spline"
          dur="1s"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="scale"
          values="0;1;0"
        />
      </circle>
    </g>
    <g transform="translate(80 50)">
      <circle r="6">
        <animateTransform
          attributeName="transform"
          begin="0s"
          calcMode="spline"
          dur="1s"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="scale"
          values="0;1;0"
        />
      </circle>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: "#FA2800",
    },
    defaultClass: {
      type: String,
      default: "text-primary",
    },
  },
};
</script>

<style lang="scss" scoped></style>
