<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
  >
    <defs>
      <clipPath id="clip-path">
        <path style="fill: none" d="M0 0h30v30H0z" />
      </clipPath>
      <style>
        .cls-4 {
          fill: #333;
        }
      </style>
    </defs>
    <g style="clip-path: url(#clip-path)" id="Group_8" data-name="Group 8">
      <g id="Group_4" data-name="Group 4">
        <path
          id="Path_3"
          data-name="Path 3"
          d="M18.1 7.142h-6.473a1.3 1.3 0 0 1-1.3-1.3v-1.59a1.3 1.3 0 0 1 1.3-1.3H18.1a1.3 1.3 0 0 1 1.3 1.3v1.593a1.3 1.3 0 0 1-1.3 1.3"
          style="fill: #f55c04"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          class="cls-4"
          d="M24.287 20.365a4.55 4.55 0 0 1-4.545 4.545H9.984a4.55 4.55 0 0 1-4.545-4.545v-9.758a4.549 4.549 0 0 1 4.545-4.545h9.756a4.549 4.549 0 0 1 4.545 4.545v9.758Zm-4.545-16.1H9.984a6.353 6.353 0 0 0-6.345 6.345v9.756a6.353 6.353 0 0 0 6.345 6.345h9.756a6.353 6.353 0 0 0 6.345-6.345v-9.759a6.351 6.351 0 0 0-6.343-6.345"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          class="cls-4"
          d="M11.405 15.637a3.312 3.312 0 1 1 3.312 3.312 3.312 3.312 0 0 1-3.312-3.312m7.547 2.863a5.141 5.141 0 1 0-1.258 1.289l1.361 1.359a.9.9 0 0 0 1.277-1.27Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
