<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.72 41.6">
    <g data-name="Layer 2">
      <path
        d="M20.8 17.83h-3v-5.94h8.92V5.94h-8.89V0h-5.94v5.94h-3a8.92 8.92 0 1 0 0 17.83h3v5.95H3v5.94h8.92v5.94h5.94v-5.94h3a8.92 8.92 0 1 0 0-17.83Zm-8.91 0h-3a3 3 0 1 1 0-5.94h3Zm8.91 11.89h-3v-5.95h3a3 3 0 0 1 0 5.95Z"
        data-name="Layer 1"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>