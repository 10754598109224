<template>
  <div
    class="flex flex-col max-h-app overflow-scroll scrollbar-hide"
  >
    <div class="flex-grow overflow-x-scroll scrollbar-hide px-4">
      <p class="text-sm whitespace-pre-wrap" v-text="rule"></p>
      <div class="h-10"></div>
    </div>

    <div class="flex-none bg-white px-6 pb-6">
      <button
        type="button"
        @click="$emit('close')"
        class="
          font-bold
          text-sm
          py-4
          bg-black
          rounded-lg
          text-white
          w-full
          mt-4
        "
      >
        我知道了
      </button>
    </div>
  </div>
</template>

<script>
const rule = `1.我們對私隱及個人資料的保護

四葉草電腦有限公司致力保護您的私隱以及個人資料的保密性、完整性和安全性。在本政策中，「個人資料」指可以識別您作為一名個人的任何信息，以電子方式或其他方式記錄。它包括由您向我們提供的資料，或者我們通過下述方式及從不同的來源所收集得來的資料。個人資料可包括但不限於您的姓名、出生日期、年齡、性別、家庭狀況、聯絡資料如電話、電郵地址以及社交媒體帳戶。

為確保您能作出知情的决定，並有信心將您的個人資料委託給我們，請務必仔細閱讀本政策，以便了解我們如何以及為何使用您的個人資料，並明白我們在此方面的政策和操作。在本政策中，「處理」個人資料是指我們對個人資料執行的操作，無論是否採用自動化方式，包括（但不限於）收集、記錄、組織、構建、存儲、保留、使用、處理、傳輸、提供、披露和刪除。

2.本政策的範圍

本政策概述我們如何收集、存儲、使用和披露您的個人資料，並列出我們這樣做的法律依據。它亦向你說明您可如何查閱及更新您的個人資料，並就如何使用您的個人資料作出選擇或反對。

3.法律依據及同意

我們處理個人資料均嚴格遵照中華人民共和國澳門特別行政區（「澳門」）的法律，特別是「個人資料保護法」（第8/2005號法律），以及任何其他與隱私及個人資料保護相關的適用法律和法規。如果適用的法律要求，我們可能就處理您的個人資料尋求您的同意。如果您未有在要求時提供您的同意，我們可能無法繼續履行我們的義務或向您提供我們的服務。

4.敏感個人資料

某些類別的個人資料被視為「敏感個人資料」，包括（但不限於）政治或宗教信仰、政治或工會關係、私人生活、種族或民族血統、健康狀況（包括基因數據）、生物識別和位置。您通過我們的網站、應用程式或社交媒體平台提供、上傳或發佈的內容（例如，關於您社交活動的資訊或照片）可能會披露敏感的個人資料。您亦應該仔細考慮您應否透露您的敏感個人資料。

5.我們收集的個人資料以及我們如何收集

我們僅收集與我們提供的服務直接相關且屬必要的個人資料。透過向我們提供您的個人資料，您承認您在提供此類個人資料時已做出完全知情的決定。依據您與我們互動的方式（例如：線上），我們可能會向您收集不同類型的信息，如下：
  (i)讓我們與您聯絡的資料，例如您的姓名、郵寄地址、電郵地址、社交網絡帳戶或電話號碼。
  (ii)讓您登入特定帳戶時所需的資料，例如登入名稱／電郵地址、用戶名、無法還原的密碼。
  (iii)您的行為特徵的資料，包括出生日期、年齡、性別、地理位置、愛好或興趣以及其他生活方式的資料。
  (iv)關於您對使用我們服務的經驗而自願與我們分享的資料。
  (v)您在社交媒體上創建並與我們分享的內容，又或上傳至我們的網站或應用程式的內容，當中包括使用社交媒體應用程式。
  (vi)您在社交媒體上公開分享的信息，或者您在第三方社交媒體上的個人資料，並允許第三方社交媒體與我們分享的資料。

6.我們處理您的個人資料的目的

為以下目的（並非所有目的都與您相關），我們在適用的法律允許的範圍內或按法律要求處理您的個人資料：
  (i)驗證您的身份（姓名、性別、出生日期或年齡），以處理您的申請和向你提供服務。
  (ii)回覆您向我們所作的查詢或投訴。這通常需要使用您的聯絡方式和與查詢原因有關的資料。
  (iii)通知您可能感興趣的服務（在適用的法律要求的情況下，須經您的同意）。通常，我們在適用的法律允許的範圍內透過電子郵件或社交媒體進行這些活動。您可以隨時選擇取消訂閱、退出或反對為此目的而處理您的個人資料。
  (iv)開發新服務，以及為您提供個人化服務，改進我們的服務。
  (v)其他與我們業務相關的一般用途，例如促進我們業務和我們提供給客戶的服務及產品的日常管理和營運，監控本網站的使用及安全，實施內部控制，以及製備統計數字、進行規劃和研究等目的；或設計服務和產品供客戶使用；或推廣、市場調查等目的；或確認、核對、記錄及維護通信和推廣的聯絡名單；或解決爭議、排除問題和執行法律聲明；或與上述任何一項直接有關的其它目的。。
  (vi)遵守法律和法規的要求，並依據適用的法律向法院、執法機關和其他政府機關提供協助。


7.處理您的個人資料之地點及期限

您的個人資料處理地是我們設在澳門的辦公室。

我們會儲存及保留您的個人資料，保存期限為實現處理目的所需的時間。而有關期限會因應資料處理之目的，又或為遵守適用的法律而有所不同。倘若法律無特定要求的情況下，我們只會在處理目的所需的最短期限內保留您的個人資料。當您的個人資料不再需要用於處理目的，或適用的法律不要求保留您的個人資料時，我們會從系統刪除您的個人資料及／或採取措施進行匿名處理，以便有關資料不可再用作識別。

8.您的權利

在適用的法律的限制下，就處理您的個人資料，您具有以下權利：
  (i)您可以向我們獲取關於您已存儲或正被處理的與您有關的個人資料的類別、資料收集方式，用途以及資料已被或將被披露的接收人士的資訊，和儲存的預計期限。
  (ii)如個人資料不正確或不完整，您可以要求修正或補充。
  (iii)如資料處理已無法律依據，或者法律依據根據適用的法律已不再適用，您可以要求刪除您的資料。本條同樣適用於如果資料處理背後的目的已失效或因其他原因不再適用的情況。
  (iv)您有權基於您的合法利益，以您特定情況的相關理由，在任何時候反對我們處理您的個人資料或者收回您的同意（當處理為基於同意時）；而我們可能亦會被要求不再處理您的個人資料。如果您的異議合理，我們將不再為此目的處理您的個人資料。

您可以通過向以下所指明的聯絡人發送書面請求，以行使上述權利。

9.本政策的更改

倘若我們更改處理您的個人資料的方式，我們會更新本政策。我們保留權利隨時變更我們的操作及本政策，並且我們邀請您經常查看本政策是否有任何更新或更改。繼續使用我們的服務，即表示您同意受不時修訂的本政策之約束。

10.聯絡資料

我們有責任尊重您的私隱並非常重視您的個人資料。
有關上述任何政策和操作的資訊，請與我們聯絡：

電話：63400720
電郵：info@iclover.net`;
export default {
  data() {
    return {
      rule,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>