<template>
  <div class="m-auto max-w-xs w-full">
    <div class="text-center mb-8">
      <h1 class="text-4xl font-bold">
        <span>{{ error.statusCode }}</span
        ><br />

        <template v-if="error.statusCode === 404">
          <span>網頁找不到</span>
        </template>
        <template v-else>
          <span>錯誤</span>
        </template>
      </h1>
    </div>

    <p class="text-center">
      <NuxtLink to="/" class="text-primary" @click="GoBack">返回首頁</NuxtLink>
    </p>
  </div>
</template>




<script>
export default {
  props: ["error"],
  layout: "page", // you can set a custom layout for the error page
};
</script>