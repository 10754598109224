export const state = () => ({
  customers: [],
  dialog: {
    show: false,
    dom: '',
    data: {},
    events: {},
    options: {}
  }
})

export const getters = {
  customers(state) {
    return state.customers
  },
  dialog(state) {
    return state.dialog
  }
}

export const mutations = {
  SET_CUSTOMERS(state, customers) {
    state.customers = customers
  },
  SET_DIALOG(state, dialog) {
    state.dialog = dialog
  },
}

export const actions = {
  fetchCustomers({ commit }) {
    return this.$axios.get("/customers").then((resp) => {
      commit('SET_CUSTOMERS', resp.data.data)
    });
  },

  showDialog({ commit }, dialog) {
    commit('SET_DIALOG', {
      show: true,
      ...dialog
    })
  },

  closeDialog({ commit }) {
    commit('SET_DIALOG', {
      show: false,
      dom: '',
      data: {},
      events: {},
      options: {}
    })
  }


}
