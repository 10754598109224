<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.23 42.95">
    <g data-name="Layer 2">
      <path
        d="M37.82 3.86 34.43.42a1.38 1.38 0 0 0-1-.42 1.41 1.41 0 0 0-1 .42L12.66 20.48a1.37 1.37 0 0 0-.31.48l-2.2 5.63a1.41 1.41 0 0 0 1.32 1.93 1.37 1.37 0 0 0 .52-.1l5.57-2.21a1.31 1.31 0 0 0 .48-.32l19.78-20a1.41 1.41 0 0 0 0-2.03ZM16.24 23.69l-2.27.9.91-2.33L33.43 3.43l1.4 1.42ZM30.68 22v19.06a2 2 0 0 1-2 1.89H1.89A1.89 1.89 0 0 1 0 41.06V5.11A1.83 1.83 0 0 1 1.89 3.3h19.62a1.89 1.89 0 1 1 0 3.78H3.78v32.1H26.9V22a1.89 1.89 0 0 1 3.78 0Z"
        data-name="Layer 1"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>