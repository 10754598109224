<template>
  <div>
    <DialogLayout v-if="show" @close="() => (show = false)" view="side-up">
      <WechatChatCard :image="image" :wx="wx" />
    </DialogLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogLayout from "@luminexs/components/modal/Modal.vue";
import WechatChatCard from "./WechatChatCard.vue";
import { find } from "lodash";

export default {
  components: {
    DialogLayout,
    WechatChatCard,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    openChatBox(customer_service) {
      if (customer_service == "wechat") {
        this.show = true;
        return;
      }

      if (customer_service == "facebook") {
        this._goSocial(this.fb);
        return;
      }

      this._goSocial(this.ig);
    },
  },
  computed: {
    ...mapGetters({
      shop: "cakeshop/shop/info",
    }),
    image() {
      return this.shop.wxqrcode;
    },
    ig() {
      return find(this.shop.social, { id: "instagram" }).data;
    },
    fb() {
      return find(this.shop.social, { id: "facebook" }).data;
    },
    wx() {
      return find(this.shop.social, { id: "wechat" }).data;
    },
  },
  mounted() {
    this.$nuxt.$on("customer_service", (customer_service) => {
      this.openChatBox(customer_service ?? this.shop.customer_service);
    });
  },
  beforeDestroy() {
    this.$nuxt.$off("customer_service");
  },
};
</script>

<style lang="scss" scoped></style>
