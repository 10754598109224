// export const strict = false
function getType(route_name) {
  if (route_name.startsWith('s-shop')) {
    return 'ushop'
  }

  if (route_name.startsWith('myshops-shop')) {
    return 'shop'
  }

  if (route_name.startsWith('id')) {
    return 'page'
  }

  return false;
}
export const actions = {
  async nuxtServerInit({ dispatch, state }, { app, route, redirect, $auth }) {
    // if route query has token then set token and replace route
    if (route.query.token && !(route.name.search('feed-id') > -1)) {
      // await app.$axios.setToken(route.query.token, 'Bearer')
      await app.$auth.setUserToken(route.query.token)
      const redirect_path = route.query.redirect || '/home'
      redirect(app.localePath(redirect_path))
      return;
    }

    // 檢查route name 是否有
    const type = getType(route.name);
    if (type) {
      const id = route.params.shop || route.params.id;
      let shop = false;
      try {
        console.log('set mainifest');
        if (type === 'ushop') {
          await dispatch("cakeshop/shop/fetch", id);
          shop = state.cakeshop.shop.shop;
        } else if (type === 'shop') {
          await dispatch("myshop/fetch", id);
          shop = state.myshop.shop;
          console.log('fetch shop', shop);
        }
        const manifest_url = `${process.env.API_URL}/manifest.json?type=${type}&id=${id}`;

        // add manifest to head
        app.head.link.push({
          rel: "manifest",
          href: manifest_url,
          hid: 'manifest'
        })

        // add icon to head
        app.head.link.push({
          rel: "icon",
          href: shop.logo,
          type: 'image/x-icon',
        })

        // add apple-touch-icon to head
        app.head.link.push({
          hid: 'apple-touch-icon',
          rel: "apple-touch-icon",
          sizes: '512x512',
          href: shop.logo
        })

        // add shortcut icon to head
        app.head.link.push({
          hid: 'shortcut-icon',
          rel: "shortcut icon",
          href: shop.logo
        })

        // app.head.link.forEach((l) => {
        //   if (l.rel == "manifest") {
        //     // change manifest
        //     l.href = manifest_url;
        //   }
        //   // change icon
        //   if (shop && ["apple-touch-icon", "icon", "shortcut icon"].includes(l.rel || l.ref)) {
        //     l.href = shop.logo;
        //   }
        // });

        console.log(app.head.link);
      } catch (e) {
        console.error(e);
        redirect(app.localePath("/"));
      }
    }else {
      app.head.link.push({
        rel: "manifest",
        href: `${process.env.API_URL}/manifest.json`,
        hid: 'manifest'
      })

      app.head.link.push({
        rel: "icon",
        href: '/icon.png?v1',
        type: 'image/x-icon',
      })
    }
  },

}

