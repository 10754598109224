<template>
  <form action="#" @submit.prevent="submit">
    <FieldWithLabel
      v-model="keywords"
      :placeholder="placeholder"
      type="search"
      id="search"
    />
    <pagination v-if="meta" :meta="meta" @switched="submit" />
  </form>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "搜尋",
    },
    meta: Object,
  },
  data() {
    return {
      keywords: this.$route.query.keywords,
    };
  },
  methods: {
    submit(page) {
      const formdata = { keywords: this.keywords, page: page ?? 1 };
      this.mapQuery(formdata);
      this.$emit("submitted", formdata);
      this.$emit("fetch", formdata);
    },
  },
};
</script>

<style lang="scss" scoped></style>
