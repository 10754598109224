export const state = () => ({
  accounts: [],
  owner: '',
})


export const getters = {
  accounts: (state) => {
    return state.accounts;
  },
  pages: (state) => {
    return state.accounts.map((account) => {
      return {
        id: account.id,
        access_token: account.access_token,
        name: account.name,
        picture: account.photos?.data[0]?.picture,
        type: 'facebook',
        owner: state.owner
      }
    })
  },
  instagram: (state) => {
    return state.accounts.filter((account) => {
      return account.connected_instagram_account
    }).map((record) => {
      const account = record.connected_instagram_account;
      return {
        id: account.id,
        access_token: record.access_token,
        name: `${account.name} (@${account.username})`,
        url: "https://www.instagram.com/" + account.username,
        picture: account.profile_picture_url,
        followers_count: account.followers_count,
        follows_count: account.follows_count,
        media_count: account.media_count,
        type: 'instagram',
        owner: state.owner
      };
    })
  }
}


export const mutations = {
  SET_ACCOUNTS(state, response) {
    if (!response.accounts) {
      state.accounts = [];
      state.owner = ''
      return;
    }


    state.owner = response.name

    state.accounts = response.accounts.data;
  }
}

export const actions = {

  fetch({ commit }, token) {
    return new Promise((resolve, reject) => {
      FB.api(
        "/me",
        "GET",
        {
          access_token: token,
          fields: "id,name,accounts{id,name,access_token,photos{picture},connected_instagram_account{id,name,username,profile_picture_url,followers_count,media_count,follows_count}}",
        },
        (response) => {
          if (response.error) {
            reject(response);
          } else {
            commit('SET_ACCOUNTS', response)
            resolve(response);
          }
        });
    })
  },

  fetchMedia({ commit }, { id, token, options = {} }) {
    return new Promise((resolve, reject) => {
      FB.api(`/${id}/media`, 'GET', {
        access_token: token,
        fields: 'caption,id,like_count,media_type,media_url,comments_count,children{media_url},thumbnail_url,timestamp,permalink',
        ...options,
      }, (response) => {
        if (response.error) {
          reject(response);
        } else {
          resolve(response);
        }
      })
    })
  },

  getMediaUrlById({ commit }, { id, token, platform, options = {} }) {
    return new Promise((resolve, reject) => {
      FB.api(`/${id}`, 'GET', {
        access_token: token,
        fields: platform === 'instagram' ? 'permalink' : 'link',
        ...options,
      }, (response) => {
        if (response.error) {
          reject(response);
        } else {
          resolve(response);
        }
      })
    })
  }


} 