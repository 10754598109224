const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['clearValidationErrors'] = require('../middleware/clearValidationErrors.js')
middleware['clearValidationErrors'] = middleware['clearValidationErrors'].default || middleware['clearValidationErrors']

middleware['dating'] = require('../middleware/dating.js')
middleware['dating'] = middleware['dating'].default || middleware['dating']

middleware['permissions'] = require('../middleware/permissions.js')
middleware['permissions'] = middleware['permissions'].default || middleware['permissions']

middleware['RequireSignup'] = require('../middleware/RequireSignup.js')
middleware['RequireSignup'] = middleware['RequireSignup'].default || middleware['RequireSignup']

export default middleware
