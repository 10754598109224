<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 137" fill="none" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 288">
      <g data-name="Group 236" clip-path="url(#a)">
        <path
          data-name="Path 209"
          d="M8.467 3.59a4.727 4.727 0 0 0-4.722 4.722v7.031a4.727 4.727 0 0 0 4.722 4.722h7.031a4.727 4.727 0 0 0 4.722-4.722V8.312a4.727 4.727 0 0 0-4.722-4.722Zm7.031 17.916H8.467a6.169 6.169 0 0 1-6.162-6.162V8.313a6.169 6.169 0 0 1 6.162-6.162h7.031a6.17 6.17 0 0 1 6.162 6.162v7.031a6.168 6.168 0 0 1-6.162 6.162"
          fill="#333"
        />
        <path
          data-name="Path 210"
          d="M11.978 7.052a2.233 2.233 0 1 0 2.238 2.229 2.236 2.236 0 0 0-2.233-2.233m0 5.906a3.673 3.673 0 1 1 3.673-3.673 3.677 3.677 0 0 1-3.673 3.673"
          fill="#333"
        />
        <path
          data-name="Path 211"
          d="M16.012 17.817H7.955a.866.866 0 0 1-.866-.865v-1.2a.867.867 0 0 1 .866-.865h8.056a.866.866 0 0 1 .865.865v1.2a.863.863 0 0 1-.865.865"
          fill="#f55c04"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>