<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" class="w-6 h-6">
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 137" fill="none" d="M0 0h30v30H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 237">
      <g data-name="Group 236" clip-path="url(#a)">
        <path
          data-name="Path 209"
          d="M10.584 4.487a5.908 5.908 0 0 0-5.9 5.9v8.789a5.908 5.908 0 0 0 5.9 5.9h8.789a5.908 5.908 0 0 0 5.9-5.9v-8.789a5.908 5.908 0 0 0-5.9-5.9Zm8.789 22.4h-8.789a7.712 7.712 0 0 1-7.7-7.7v-8.789a7.712 7.712 0 0 1 7.7-7.7h8.789a7.713 7.713 0 0 1 7.7 7.7v8.789a7.711 7.711 0 0 1-7.7 7.7"
          fill="#333"
        />
        <path
          data-name="Path 210"
          d="M14.979 8.816a2.792 2.792 0 1 0 2.792 2.792 2.8 2.8 0 0 0-2.792-2.792m0 7.382a4.592 4.592 0 1 1 4.592-4.592 4.6 4.6 0 0 1-4.592 4.592"
          fill="#333"
        />
        <path
          data-name="Path 211"
          d="M20.015 22.272H9.947a1.083 1.083 0 0 1-1.083-1.081v-1.5a1.084 1.084 0 0 1 1.083-1.082h10.07a1.082 1.082 0 0 1 1.081 1.081v1.5a1.079 1.079 0 0 1-1.081 1.081"
          fill="#5ca9f7"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>