<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    data-name="Layer 2"
  >
    <g data-name="Graphic Elements">
      <path
        fill="#2c5ba6"
        d="M30 14.996A15 15 0 1 0 12.666 29.81V19.327H8.847v-4.331h3.819v-3.3c0-3.76 2.223-5.835 5.656-5.835a23.38 23.38 0 0 1 3.358.293v3.69h-1.891a2.167 2.167 0 0 0-2.445 2.342v2.813h4.16l-.667 4.331h-3.493v10.477A15 15 0 0 0 30 14.996Z"
        data-name="Path 1"
      />
      <path
        fill="#fff"
        d="m21.072 18.866.7-4.525H17.43v-2.934a2.262 2.262 0 0 1 2.552-2.445h1.973V5.11a24.326 24.326 0 0 0-3.5-.306c-3.584 0-5.9 2.166-5.9 6.092v3.448H8.569v4.522h3.97v10.942a15.838 15.838 0 0 0 4.895 0V18.866Z"
        data-name="Path 2"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>