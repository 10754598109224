<template>
  <!-- <div v-html="svg"  /> -->
  <img :src="svg" />
  
</template>

<script>
// https://avatars.dicebear.com/api/avataaars/${username}.svg
// import { createAvatar } from "@dicebear/avatars";
// import * as style from "@dicebear/avatars-avataaars-sprites";

// import { createAvatar } from "@dicebear/core";
// import { adventurer } from "@dicebear/collection";

// const avatar = createAvatar(adventurer, {
//   seed: "Loki",
//   backgroundColor: ["b6e3f4", "c0aede", "d1d4f9", "ffd5dc", "ffdfbf"],
// });

// const svg = avatar.toString();
export default {
  props: {
    username: {
      type: String,
    },
    flip: {
      type: Boolean,
    },
  },
  computed: {
    svg() {
      let url = 'https://api.dicebear.com/7.x/notionists/svg/'
      const options = {
        seed: this.username,
        backgroundColor: ["b6e3f4", "c0aede", "d1d4f9", "ffd5dc", "ffdfbf"],
        flip: this.flip,
      }

      const query = Object.keys(options)
        .map((key) => `${key}=${options[key]}`)
        .join("&");

      return `${url}${query}`;
    },


  },
};
</script>

<style lang="scss" scoped>
</style>