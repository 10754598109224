<template>
  <form class="max-h-500 flex flex-col" action="#" @submit.prevent="submit">
    <div class="flex-grow overflow-scroll px-4 pt-4">
      <div class="flex mt-4">
        <p class="flex-none text-lg text-gray-800 mt-2 w-1/4 text-center">+853</p>

        <FieldWithLabel
          class="flex-grow"
          id="tel"
          v-model="form['tel']"
          placeholder="電話號碼"
          :disabled="submitting"
        />
      </div>

      <FieldWithLabel
        id="password"
        type="password"
        v-model="form['password']"
        placeholder="新的密碼"
        :disabled="submitting"
      />

      <FieldWithLabel
        id="password_confirmation"
        type="password"
        v-model="form['password_confirmation']"
        placeholder="重複新的密碼"
        :disabled="submitting"
      />

      <div class="flex space-x-4">
        <FieldWithLabel
          class="flex-grow"
          id="code"
          v-model="form['code']"
          placeholder="短訊認證碼"
          :disabled="submitting"
        />

        <div class="flex-none">
          <ValidationButton :disabled="submitting" :tel="form['tel']" type="reset" />
        </div>
      </div>
      <div class="h-10"></div>
    </div>
    <div class="flex-none bg-white pb-6 px-4">
      <button type="submit" class="btn w-full bbm mb-4" :disabled="submitting">
        重設密碼
      </button>
    </div>
  </form>
</template>

<script>
import ValidationButton from "./ValidationButton.vue";
export default {
  components: {
    ValidationButton,
  },
  data() {
    return {
      form: {},
      submitting: false,
    };
  },
  methods: {
    async submit() {
      if (this.submitting) return;
      try {
        this.submitting = true;
        await this.verify();
        await this.$axios.post("/dating/reset", this.form);

        await this.verify();
        await this.$auth.loginWith("local", { data: this.form });
        this.saveAppToken();
        this.fetchWallet();

        this.$emit("submited");

        // send token to server alongside your form data
      } catch (error) {
        console.error("Login error:", error);
      } finally {
        this.submitting = false;
      }
    },
    signin() {
      this.$emit("changeto", "signin");
    },
  },
  beforeDestroy() {
    this.clearErrors();
  },
};
</script>

<style lang="scss" scoped></style>
