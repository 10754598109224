import { render, staticRenderFns } from "./SignupTerms.vue?vue&type=template&id=2cf6724d&scoped=true"
import script from "./SignupTerms.vue?vue&type=script&lang=js"
export * from "./SignupTerms.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cf6724d",
  null
  
)

export default component.exports