<template>
  <button
    class="btn bbm"
    type="button"
    :disabled="disabled || count > 0"
    @click="validation"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    type: {
      type: String,
      default: 'signup'
    },
    tel: [String, Number],
  },
  data() {
    return {
      count: 0,
      countdown: null,
    };
  },

  methods: {
    counting() {
      this.count = 180;
      this.countdown = setInterval(() => {
        this.count -= 1;

        if (this.count <= 0) clearInterval(this.countdown);
      }, 1000);
    },
    async validation() {
      if (this.count) return;

      try {
        await this.$axios.post("/dating/validation", {
          tel: this.tel,
          type: this.type
        });

        this.counting();
      } catch (e) {}
    },
  },
  computed: {
    text() {
      return this.count ? this.count : "認證碼";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>