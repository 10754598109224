<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 34 25"
  >
    <defs>
      <linearGradient
        id="a"
        y1="12.5"
        x2="34"
        y2="12.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#1d93d2" />
        <stop offset="1" stop-color="#38b0e3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="4.25"
        y1="15.59"
        x2="27.71"
        y2="15.59"
        xlink:href="#a"
      />
      <linearGradient id="c" x1="17" y1="8" x2="25" y2="8" xlink:href="#a" />
    </defs>
    <g data-name="Layer 2">
      <path
        d="M31 0H3a3 3 0 0 0-3 3v19a3 3 0 0 0 3 3h28a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3Zm1 22a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h28a1 1 0 0 1 1 1Z"
        fill="url(#a)"
      />
      <path
        fill="url(#b)"
        d="m18.21 17.71-6.2-7.24-7.76 8.87 1.5 1.32 6.24-7.13 5.8 6.76 5.05-3.03 3.45 3.45 1.42-1.42-4.55-4.55-4.95 2.97z"
      />
      <path
        d="M21 12a4 4 0 1 0-4-4 4 4 0 0 0 4 4Zm0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2Z"
        fill="url(#c)"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>