<template>
  <div class="grid grid-cols-3 text-xs mb-safe">
    <nuxt-link
      v-for="(nav, index) in navs"
      :key="index"
      custom
      v-slot="{ navigate }"
      :to="nav.to"
    >
      <button
        type="button"
        class="py-2 text-center cursor-pointer block"
        @click="navigate"
      >
        <div class="inline-block text-center">
          <div><icon :icon="nav.icon" class="inline-block h-6 w-6" /></div>
          <p>{{ nav.text }}</p>
        </div>
      </button>
    </nuxt-link>
  </div>
</template>

<script>
const navs = [
  { text: "抽盒記錄", icon: "draw", to: "/boxboxmo/records" },
  { text: "交友盲盒", icon: "BoxIcon", to: "/boxboxmo" },
  { text: "我的", icon: "MeIcon", to: "/boxboxmo/me" },
];

export default {
  data() {
    return {
      navs,
    };
  },
};
</script>

<style lang="scss" scoped></style>
