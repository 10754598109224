<template>
  <div >
    <div class="h-20"></div>
    <p class="text-center text-xs">
      Developed by
      <a href="https://ushop.unknoown.com/" target="_blank" class="text-floral-primary font-bold"
        >CLOVER Computer Ltd.</a
      >
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
