

export const state = () => ({
  shop: {
    currency: 'MOP',
    colors: [],
    shippings: [],
    domain: '',
    category: ''
  },
  variations: [],
  categories: [],
  options: {},
  fieldtypes: [
    {text: '文字（50字內）', value: 'text'},
    {text: '長文字（250字內）', value: 'textarea'},
    {text: '網址', value: 'url'},
    {text: '電郵', value: 'email'},
  ]
})

export const getters = {
  fieldtypes: ({ fieldtypes }) => fieldtypes,
  shop({ shop }) {
    return shop
  },
  currency({ shop }) {
    return shop.currency
  },
  domain({ shop }) {
    return shop.domain
  },
  shippings({ shop }) {
    return shop.shippings
  },
  colors({ shop }) {
    return shop.colors
  },
  variations({ variations }) {
    return variations
  },
  categories({ categories }) {
    return categories
  },
  
  options({ options }) {
    return options
  },
  isCakeshop({ shop }) {
    return shop.category == 'cakeshop'
  },
  isBooking({ shop }) {
    return shop.category == 'booking'
  }

}

export const mutations = {
  SET_SHOP(state, data) {
    state.shop = data
  },
  SET_VARIATIONS(state, data) {
    state.variations = data
  },
  SET_CATEGORIES(state, data) {
    state.categories = data
  },

  SET_OPTIONS(state, { data, type }) {
    state.options[type] = data
  }
}

export const actions = {
  fetch({ commit }, shop) {
    return this.$axios.get(`/myshops/${shop}`)
      .then(({ data }) => {
        commit('SET_SHOP', data.data)
      })
  },

  fetchVariations({ commit }, shop) {
    return this.$axios.get(`/myshops/${shop}/variations`)
      .then(({ data }) => {
        commit('SET_VARIATIONS', data.data)
      })
  },

  fetchCategories({ commit }, shop) {
    return this.$axios.get(`/myshops/${shop}/categories`)
      .then(({ data }) => {
        commit('SET_CATEGORIES', data.data)
      })
  },

  fetchOptions({ commit }, { shop, type }) {
    return this.$axios.get(`/myshops/${shop}/options?type=${type}`)
      .then(({ data }) => {
        commit('SET_OPTIONS', {
          data: data.data,
          type
        })
      })
  },

  

}