<template>
  <div class="md:py-8 py-2 text-center text-xs mb-safe">
    <p class="mb-4 leading-5">
      <span>©2021 - {{ year }} Developed by</span>
      <a href="https://iclover.net" target="_blank" class="text-primary block"
        >CLOVER COMPUTER LTD.</a
      >
    </p>
    <p class="mb-4">
      <nuxt-link class="text-primary" :to="localePath({ name: 'docs-privacy' })"
        >隱私政策</nuxt-link
      >
      &
      <nuxt-link class="text-primary" :to="localePath({ name: 'docs-services' })"
        >服務條款</nuxt-link
      >
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped></style>
