<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    class="w-6 h-6 fill-current"
  >
    <defs>
      <clipPath id="clip-path">
        <path style="fill: none" d="M0 0h30v30H0z" />
      </clipPath>
      <style>
        .cls-4 {
          fill: #333;
        }
      </style>
    </defs>
    <g style="clip-path: url(#clip-path)" id="Group_14" data-name="Group 14">
      <g id="Group_13" data-name="Group 13">
        <path
          id="Path_16"
          data-name="Path 16"
          d="M23.759 26.012H20.45a2.97 2.97 0 0 1-2.968-2.968v-8.359a2.968 2.968 0 0 1 2.968-2.968h3.309a2.967 2.967 0 0 1 2.968 2.966v8.362a2.967 2.967 0 0 1-2.968 2.966"
          style="fill: #f55c04"
        />
        <path
          id="Path_17"
          data-name="Path 17"
          class="cls-4"
          d="M9.2 4.506a3.683 3.683 0 0 0-3.683 3.679v13.343A3.684 3.684 0 0 0 9.2 25.206h8.031a3.684 3.684 0 0 0 3.669-3.678V8.185a3.684 3.684 0 0 0-3.678-3.678Zm8.031 22.5H9.2a5.484 5.484 0 0 1-5.479-5.477V8.185A5.484 5.484 0 0 1 9.2 2.706h8.031a5.484 5.484 0 0 1 5.479 5.479v13.343a5.486 5.486 0 0 1-5.479 5.48"
        />
        <path
          id="Path_18"
          data-name="Path 18"
          class="cls-4"
          d="M16.021 17.939h-6.7a.9.9 0 1 1 0-1.8h6.7a.9.9 0 1 1 0 1.8m0-6.71h-6.7a.9.9 0 1 1 0-1.8h6.7a.9.9 0 1 1 0 1.8"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>