<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 126.57 201"
    class="w-6 h-6 fill-current"
  >
    <defs />
    <path
      d="M104 22.58v115.14a40.52 40.52 0 0 1-40.71 40.39c-22.26 0-40.7-18.13-40.7-40.39V104L0 126.57v11.15a63.29 63.29 0 0 0 126.57 0V0ZM22.58 54.86v-54L0 23.44v54l22.58-22.58z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>