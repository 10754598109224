<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 33 24"
  >
    <defs>
      <linearGradient
        id="a"
        y1="14.47"
        x2="33"
        y2="14.47"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#1d93d2" />
        <stop offset="1" stop-color="#38b0e3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="2.95"
        y1="2.9"
        x2="30.05"
        y2="2.9"
        xlink:href="#a"
      />
    </defs>
    <g data-name="Layer 2">
      <g data-name="Icon">
        <path
          d="M32.3 11.77C28.29 7.18 23.12 5 16.5 5c-.69 0-1.39 0-2.07.09A19.79 19.79 0 0 0 .71 11.76a2.86 2.86 0 0 0-.43 3.12C1.58 17.62 5.77 24 16.5 24s14.92-6.38 16.22-9.12a2.86 2.86 0 0 0-.42-3.11Zm-15.8 2.05a5 5 0 0 0 5-4.9 4.72 4.72 0 0 0-.26-1.49 17.69 17.69 0 0 1 3.24 1 8.22 8.22 0 0 1-8 7.49 8.29 8.29 0 0 1-8-7.41 19.06 19.06 0 0 1 3.21-1 4.86 4.86 0 0 0-.24 1.42 5 5 0 0 0 5.05 4.89ZM14.62 7h1.88c.79 0 1.55 0 2.29.1a2.71 2.71 0 0 1 .74 1.85 3 3 0 0 1-6 0 2.68 2.68 0 0 1 .73-1.83Zm16.3 7c-1.14 2.4-4.83 8-14.42 8S3.23 16.42 2.09 14a.89.89 0 0 1 .12-.95 17.35 17.35 0 0 1 4.43-3.62 10.4 10.4 0 0 0 9.86 8.48 10.33 10.33 0 0 0 9.88-8.54 18 18 0 0 1 4.41 3.71.87.87 0 0 1 .13.92Z"
          fill="url(#a)"
        />
        <path
          d="M16.5 2A22.45 22.45 0 0 1 29 5.79l1.1-1.67a24.34 24.34 0 0 0-27.1 0l1.1 1.67A22.45 22.45 0 0 1 16.5 2Z"
          fill="url(#b)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>