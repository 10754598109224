import axios from 'axios'

const http = axios.create({
  baseURL: 'https://graph.instagram.com'
})

export const state = () => ({

})


export const getters = {

}

export const mutations = {
}

export const actions = {
  fetchMedia({ }, { token, after, before }) {
    return http.get('/v19.0/me/media', {
      params: {
        access_token: token,
        after,
        before,
        fields: 'id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username',
        limit: 28,
        pretty: 1
      }
    })
  },

  fetchMe({}, token) {
    return http.get('/v19.0/me', {
      params: {
        access_token: token,
        fields: 'id,account_type,media_count,username'
      }
    })
  }
}