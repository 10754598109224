<template>
  <form class="max-h-500 flex flex-col" action="#" @submit.prevent="submit">
    <div class="flex-grow overflow-scroll pt-4 px-4">
      <div class="flex">
        <p class="flex-none text-lg text-gray-800 mt-2 w-1/4 text-center">+853</p>
        <FieldWithLabel
          id="tel"
          v-model="form['tel']"
          placeholder="電話號碼"
          :disabled="submitting"
          class="flex-grow"
        />
      </div>

      <FieldWithLabel
        id="password"
        type="password"
        v-model="form['password']"
        placeholder="登入密碼"
        :disabled="submitting"
      />

      <FieldWithLabel
        id="password_confirmation"
        type="password"
        v-model="form['password_confirmation']"
        placeholder="再次登入密碼"
        :disabled="submitting"
      />

      <div class="flex space-x-4 mb-4">
        <FieldWithLabel
          id="code"
          v-model="form['code']"
          placeholder="短訊認證碼"
          class="flex-grow"
          :disabled="submitting"
        />

        <div class="flex-none">
          <ValidationButton :disabled="submitting" :tel="form['tel']" />
        </div>
      </div>

      <v-field :error="errors.agree" class="text-center mb-4 tracking-widest text-sm">
        <div class="text-center flex justify-center">
          <label class="text-sm"
            ><input type="checkbox" class="rounded-full mr-1" v-model="form.agree" />
            本人已閱讀及同意接受</label
          >
          <a class="text-bbmprimary underline" @click.prevent="readTerms">使用條款</a>
        </div>
      </v-field>

      <div class="text-center">
        <button
          type="button"
          class="mb-4 text-bbmprimary font-medium"
          @click="signin"
          :disabled="submitting"
        >
          會員登入
        </button>
      </div>

      <div class="h-10"></div>
    </div>

    <div class="flex-none bg-white pb-6 px-4">
      <button type="submit" class="btn w-full bbm mb-4" :disabled="submitting">
        註冊
      </button>
    </div>
  </form>
</template>

<script>
import ValidationButton from "./ValidationButton.vue";
export default {
  components: {
    ValidationButton,
  },
  props: {
    agree: Boolean,
  },
  data() {
    return {
      form: {
        agree: this.agree,
        invitedby: this.$cookies.get("invitedby"),
      },
      submitting: false,
    };
  },
  methods: {
    async submit() {
      if (this.submitting) return;
      try {
        this.submitting = true;
        await this.verify();
        await this.$axios.post("/dating/signup", this.form);

        this.$auth.options.redirect = this.$route.path;
        await this.$auth.loginWith("local", { data: this.form });
        this.saveAppToken();
        await this.clearCookies();
        this.fetchWallet();

        this.$emit("submited");

        // send token to server alongside your form data
      } catch (error) {
        console.error("Login error:", error);
      } finally {
        this.submitting = false;
      }
    },
    signin() {
      this.$emit("changeto", "signin");
    },

    readTerms() {
      this.$emit("changeto", "terms");
    },

    async verify() {
      const token = await this.$recaptcha.execute("login");
      this.form["g-recaptcha-response"] = token;
    },
  },

  beforeDestroy() {
    this.clearErrors();
  },
};
</script>

<style lang="scss" scoped></style>
