import {each} from 'lodash'

export default function ({ $auth, redirect }) {
  if ($auth.loggedIn) {
    const {permissions} = $auth.user
    const scopes = ['manage_pages', 'publish_pages']

    each(permissions, i => {
      if (scopes.includes(i.permission) && i.status == 'declined') {
        return redirect({name: 'permissions'})
      }
    })
  }
}
