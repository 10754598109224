<template>
  <transition name="menu">
    <div class="fixed inset-0 z-99" v-if="show">
      <div class="absolute inset-0 bg-black bg-opacity-25 -z-10" />

      <!-- left menu list -->
      <div class="w-full max-w-screen-sm mx-auto h-full z-10 overflow-hidden">
        <div
          class="h-full w-4/5 bg-f8 overflow-y-scroll overflow-x-hidden scrollbar-hide shadow slideInFromLeft"
          id="menu"
        >
          <div class="h-16 flex border-b-2 border-floral-primary items-center">
            <div class="aspect-square h-full overflow-hidden flex-none p-2">
              <img
                :src="shop.logo"
                :alt="shop.name"
                width="80"
                height="80"
                class="w-full h-full object-center rounded-full border border-floral-primary"
              />
            </div>
            <div class="flex-grow">
              <h2 class="font-medium text-floral-primary line-clamp-1">
                {{ shop.name }}
              </h2>
            </div>

            <button
              type="button"
              class="h-full block aspect-square flex-none text-floral-primary"
              @click="toggle"
            >
              <XIcon class="mx-auto" />
            </button>
          </div>
          <NavigationByCategory
            class="divide-y"
            :categories="menu"
            :current="category"
            :preve="preve_categories"
            @select="selected"
          />

          <div class="h-80"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import NavigationByCategory from "@/components/cakeshop/product/NavigationByCategory.vue";
import { XIcon } from "@vue-hero-icons/outline";
import { mapGetters, mapActions } from "vuex";
import { first, last } from "lodash";
export default {
  components: {
    NavigationByCategory,
    XIcon,
  },
  data() {
    return {
      category: null,
      preve_categories: [],
      menu: [],
    };
  },
  methods: {
    ...mapActions({
      toggle: "cakeshop/shop/toggleCatelog",
    }),
    setMenu(menu) {
      this.menu = menu;
    },
    selected(category) {
      // 判斷 category 是否為層級
      if (category.children.length) {
        // 判斷是否為上一層
        if (this.preve_categories.includes(category)) {
          this.preve_categories.pop();
          const last_category = last(this.preve_categories);
          // 如果沒有上一層，則顯示第一層

          this.setMenu(!last_category ? this.categories : last_category.children);
          return;
        }

        this.preve_categories.push(category);
        this.setMenu(category.children);
        return;
      }
      this.toggle();
      this.category = category;
      this.$emit("selected", {
        category: category.id,
        page: 1,
      });
    },
  },
  computed: {
    ...mapGetters({
      categories: "cakeshop/shop/categories",
      products: "cakeshop/shop/products",
      ads: "cakeshop/shop/ads",
      shop: "cakeshop/shop/info",
      show: "cakeshop/shop/show_catelog",
    }),

    meta() {
      return this.products.meta || {};
    },

    list() {
      return this.products.records;
    },
  },
  mounted() {
    this.setMenu(this.categories);
    this.category = first(this.categories);
  },
};
</script>

<style lang="scss" scoped></style>
