<template>
  <nav class="flex flex-nowrap text-gray-800 pb-safe">
    <template v-for="nav in navs">
      <nuxt-link
        class="flex flex-col items-center py-1 space-y-0.5 flex-auto"
        :class="isActive(nav)"
        :to="nav.to"
        :key="nav.text"
        v-if="showable(nav)"
      >
        <component :is="nav.icon" class="w-5 h-5" />
        <span class="text-xxs tracking-wide" v-text="nav.text" />
      </nuxt-link>
    </template>
  </nav>
</template>

<script>
import {
  CollectionIcon,
  CakeIcon,
  UserIcon,
  StarIcon,
  HomeIcon,
} from "@vue-hero-icons/solid";
import { mapGetters } from "vuex";
export default {
  components: {
    CakeIcon,
    CollectionIcon,
    UserIcon,
    StarIcon,
    HomeIcon,
  },

  methods: {
    showable(nav) {
      if (typeof nav.hidden == "function") return !nav.hidden();
      return !nav.hidden;
    },
    isActive(nav) {
      const { exactActiveClass, activeClasstext, to } = nav;
      const base_class = nav.class;
      const { path } = this.$route;
      
      if (exactActiveClass && path != to) return base_class;

      if (!path.includes(to)) return base_class;

      return `${base_class} ${exactActiveClass} ${activeClasstext}`;
    },
  },

  computed: {
    ...mapGetters({
      navs: "cakeshop/shop/navs",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>