<template>
  <div class="w-full overflow-x-scroll scrollbar-hide px-4 bg-white">
    <ul
      class="
        flex flex-nowrap
        min-w-max
        items-center
        space-x-4
        text-sm text-gray-400
      "
    >
      <li>
        <BoxIcon />
      </li>
      <li>
        <nuxt-link
          to="/boxboxmo/"
          class="cursor-pointer leading-none tracking-wider py-3 relative"
          exact-active-class="font-bold text-lg text-black "
          >男神女神盒
          <span
            class="h-1 absolute bottom-0 inset-x-0 bg-black"
            v-if="isActive('boxboxmo', false)"
          ></span>
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
          to="/boxboxmo/lgbt"
          class="cursor-pointer leading-none tracking-wider py-3 relative"
          active-class="font-bold text-lg text-black "
          >LGBT盲盒
          <span
            class="h-1 absolute bottom-0 inset-x-0"
            :style="bglgbt"
            v-if="isActive('boxboxmo-lgbt')"
          ></span
        ></nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
import BoxIcon from "@/components/icons/BoxIcon";
export default {
  components: {
    BoxIcon,
  },
  data() {
    return {
      bglgbt:
        "background-image: linear-gradient(to right, #f00, #ffe200 23%, #35bf40 46%, #00ffce 76%, #ce00ff)",
    };
  },
  methods: {
    isActive(name, includes = true) {
      if (!includes) return this.$route.name == name;
      return this.$route.name.includes(name);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>