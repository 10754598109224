


export default function ({ $axios, store, redirect, app}) {
  // $axios.setHeader('X-Requested-With', 'XMLHttpRequest')
  // $axios.setHeader('Content-Type', 'application/json')

  // const token = app.$cookies.get('auth._token.'+ app.$cookies.get('auth.strategy'))
  // console.log(token);
  // if (token) {
  //   $axios.setToken(token)
  // }


  // if (process.server) {
  //   $axios.setHeader('accept-encoding', 'gzip')
  //   $axios.setHeader('cf-connecting-ip', '')
  // }

  $axios.onError(error => {
    const { method, url, baseURL } = error.response.config

    console.error(error)

    // store.dispatch('load/unsetLoading', `${url.replace(baseURL, '')}-${method}`)
    if (error.response.status === 422) {
      store.dispatch('validation/setErrors', error.response.data.errors)
    }

    if (error.response.status === 401) {
      $auth.logout()
      // clear all cookies
      app.$cookies.removeAll()
      return redirect({ name: 'index' })
    }

    return Promise.reject(error)
  })

  $axios.onRequest(config => {
    // store.dispatch('load/setLoading', `${url}-${method}`)
    store.dispatch('validation/clearErrors')



    // if (config.data) {
    //   config.data = {data: encrypter.encrypt({...config.data})}
    // }

    // return config
  })

  $axios.onResponse(({ config, $auth }) => {
    // const {method, url, baseURL} = config
    // store.dispatch('load/unsetLoading', `${url.replace(baseURL, '')}-${method}`)
  })

}

