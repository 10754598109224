export const state = () => ({
  sites: []
});


export const getters = {
  sites:(state) => state.sites,
};

export const mutations = {
  SET(state, {
    key, data
  }) {
    state[key] = data;
  },
};

export const actions = {
  setSites({commit}, data) {
    commit('SET', {
      key: 'sites',
      data
    })
  }
};