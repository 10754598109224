import { map } from 'lodash'

function filterCategory(category) {
  if (category.products_count > 0) return true;
  if (category.children.length) {
    return category.children.some(filterCategory);
  }
  return false;
}

export const state = () => ({
  shop: {
    currency: 'MOP',
  },

  products: {
    records: [],
    meta: {}
  },

  show_catelog: false,
})

export const getters = {
  info({ shop }) {
    return shop
  },

  options({ shop }) {
    return shop.options ?? {}
  },

  products({ products }) {
    return products
  },

  colors({ shop }) {
    return shop.colors
  },

  navs({ shop }) {
    return shop.navs
  },

  ads({ shop }) {
    return map(shop.ads, a => ({
      url: a.data,
      img: a.image
    }))
  },

  currency({ shop }) {
    return shop.currency
  },

  categories({ shop }) {
    const menu = shop.categories.filter(filterCategory)

    return [{
      id: '',
      name: '全部',
      children: [],
    }, ...menu];
  },

  show_catelog({ show_catelog }) {
    return show_catelog
  }

}

export const mutations = {
  SET_DATA(state, data) {
    state.shop = data
  },

  SET_PRODUCTS(state, data) {
    state.products.records = data.data
    state.products.meta = data.meta
  },

  SET_SHOW_CATELOG(state, show) {
    state.show_catelog = show
  }
}

export const actions = {
  async fetch({ commit }, shop) {
    return this.$axios.get(`/shops/${shop}`)
      .then(({ data }) => {
        commit('SET_DATA', data.data)
      })
  },

  // fetch products
  async fetchProducts({ commit }, { shop, page, category, more }) {
    return this.$axios.get(`/shops/${shop}/products`, {
      params: {
        page,
        category
      }
    })
      .then(({ data }) => {
        if (more) {
          commit('SET_PRODUCTS', {
            data: [...this.state.cakeshop.shop.products.records, ...data.data],
            meta: data.meta
          })
        } else {
          commit('SET_PRODUCTS', data)
        }
      }).catch(e => {
        console.error(e)
      })
  },

  toggleCatelog({ commit, state }) {
    commit('SET_SHOW_CATELOG', !state.show_catelog)
  },

}
