<template>
  <div>
    <div>
      <button type="button" class="text-floral-primary p-2" @click="$parent.close()">
        <XIcon />
      </button>
    </div>
    <div class="relative aspect-square bg-gray-50" v-if="image">
      <img
        :data-src="image"
        v-lazy-load
        :alt="wx"
        class="absolute w-full h-full object-contain"
      />
    </div>
    <div class="flex justify-center py-4">
      <p>微信ID：</p>
      <CopyButton :text="wx" class="text-floral-primary" />
    </div>
    <div class="h-20"></div>
  </div>
</template>

<script>
import { XIcon } from "@vue-hero-icons/outline";
import CopyButton from "@/components/button/CopyButton.vue";
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    wx: {
      type: String,
    },
  },
  components: {
    XIcon,
    CopyButton,
  },
};
</script>

<style lang="scss" scoped></style>
