<template>
  <div
    class="h-screen-ios h-screen max-h-app w-full relative flex flex-col items-stretch overflow-hidden "
  >
    <div class="flex-none pt-safe">
      <vHeader />
    </div>
    <div
      class="flex-grow self-stretch overflow-y-scroll scrollbar-hide h-full relative"
    >
      <nuxt />
    </div>

    <div class="mb-safe flex-none border-t sm:border-0" id="__footer">
      <vFooter class="hidden sm:block" />
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/Header";

export default {
  middleware: ["auth", "RequireSignup"],

  components: {
    vHeader,
  },
};
</script>
