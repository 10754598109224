import localeacfa20a8 from '../../locales/zh-mo.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"zh-mo","iso":"zh-mo","name":"繁體中文","file":"zh-mo.js","domain":"https://beta.unknoown.com"}],
  defaultLocale: "zh-mo",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "@/locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"zh-mo","iso":"zh-mo","name":"繁體中文","file":"zh-mo.js","domain":"https://beta.unknoown.com"}],
  localeCodes: ["zh-mo"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "z",
  3: "h",
  4: "-",
  5: "m",
  6: "o",
  7: ".",
  8: "j",
  9: "s",
  10: "\"",
  11: ":",
  12: "\"",
  13: ".",
  14: ".",
  15: "/",
  16: "l",
  17: "o",
  18: "c",
  19: "a",
  20: "l",
  21: "e",
  22: "s",
  23: "/",
  24: "z",
  25: "h",
  26: "-",
  27: "m",
  28: "o",
  29: ".",
  30: "j",
  31: "s",
  32: "\"",
  33: "}",
}

export const localeMessages = {
  'zh-mo.js': () => Promise.resolve(localeacfa20a8),
}
