import { render, staticRenderFns } from "./WebRuleBox.vue?vue&type=template&id=21144951&scoped=true"
import script from "./WebRuleBox.vue?vue&type=script&lang=js"
export * from "./WebRuleBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21144951",
  null
  
)

export default component.exports