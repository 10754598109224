<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 198.134 85.571"
  >
    <defs>
      <clipPath id="clip-path">
        <path class="cls-1" d="M0 0h198.134v85.571H0z" />
      </clipPath>
      <clipPath id="clip-path-2">
        <path class="cls-1" d="M9 24.285h36v36H9z" />
      </clipPath>
      <style>
        .cls-1 {
          fill: none;
        }
        .cls-3 {
          fill: #bfbbaf;
        }
        .cls-9 {
          fill: #fffdfa;
        }
        .cls-12 {
          fill: #333;
        }
      </style>
    </defs>
    <g id="Group_331" data-name="Group 331">
      <g
        style="clip-path: url(#clip-path)"
        id="Group_282"
        data-name="Group 282"
      >
        <g id="Group_281" data-name="Group 281">
          <path
            id="Path_220"
            data-name="Path 220"
            class="cls-3"
            d="M.485 12.244a.811.811 0 0 0-.121.006V8.207A7.843 7.843 0 0 0 8.182.364h43.144v84.842H8.233A7.844 7.844 0 0 0 .39 77.364H.364V73.32c.04 0 .08.006.121.006a2.383 2.383 0 0 0 0-4.766.811.811 0 0 0-.121.006v-2.285c.04 0 .08.006.121.006a2.383 2.383 0 1 0 0-4.766.811.811 0 0 0-.121.006v-2.286c.04 0 .08.006.121.006a2.383 2.383 0 0 0 0-4.766.811.811 0 0 0-.121.006V52.2c.04 0 .08.006.121.006a2.383 2.383 0 0 0 0-4.766.811.811 0 0 0-.121.006v-2.282c.04 0 .08.006.121.006a2.383 2.383 0 1 0 0-4.766.811.811 0 0 0-.121.006v-2.288c.04 0 .08.006.121.006a2.383 2.383 0 0 0 0-4.766H.364v-2.279c.04 0 .08.006.121.006a2.383 2.383 0 1 0 0-4.766.811.811 0 0 0-.121.006v-2.286c.04 0 .08.006.121.006a2.383 2.383 0 1 0 0-4.766.811.811 0 0 0-.121.006V17c.04 0 .08.006.121.006a2.383 2.383 0 0 0 0-4.766"
          />
          <path
            id="Path_221"
            data-name="Path 221"
            class="cls-3"
            d="M7.869 85.206A7.489 7.489 0 0 0 .39 77.727l-.39.006v-4.8l.43.022h.056a2.019 2.019 0 0 0 0-4.038H.43l-.43.032V65.9l.429.022h.057a2.019 2.019 0 1 0 0-4.038H.43L0 61.91v-3.051l.429.022h.057a2.019 2.019 0 0 0 0-4.038H.43L0 54.87v-3.051l.429.022h.057a2.019 2.019 0 0 0 0-4.038H.429L0 47.831v-3.052l.429.022h.056a2.019 2.019 0 0 0 0-4.038H.429L0 40.791V37.74l.429.022h.056a2.019 2.019 0 0 0 0-4.038H.429L0 33.752V30.7l.429.022h.057a2.019 2.019 0 0 0 0-4.038H.429L0 26.713V23.66l.429.022h.057a2.019 2.019 0 0 0 0-4.038H.429L0 19.673v-3.052l.429.022h.056a2.019 2.019 0 0 0 0-4.038H.429L0 12.633V7.844h.363A7.492 7.492 0 0 0 7.818.364V0H51.69v85.571H7.869ZM50.962.728H8.538a8.225 8.225 0 0 1-7.81 7.834v3.329a2.747 2.747 0 0 1 0 5.473v1.566a2.747 2.747 0 0 1 0 5.473v1.567a2.747 2.747 0 0 1 0 5.473v1.567a2.747 2.747 0 0 1 0 5.473v1.567a2.747 2.747 0 0 1 0 5.473v1.567a2.747 2.747 0 0 1 0 5.473v1.567a2.747 2.747 0 0 1 0 5.473v1.567a2.747 2.747 0 0 1 0 5.473v1.567a2.747 2.747 0 0 1 0 5.473v3.326a8.219 8.219 0 0 1 7.862 7.833h42.372Z"
          />
          <path
            id="Path_222"
            data-name="Path 222"
            d="M189.923.364a7.842 7.842 0 0 0 7.843 7.843v4.049a2.383 2.383 0 0 0-.477 4.744 2.312 2.312 0 0 0 .235.012 1.635 1.635 0 0 0 .243-.012v2.3a2.383 2.383 0 0 0-.478 4.742 2.312 2.312 0 0 0 .235.012 1.635 1.635 0 0 0 .243-.012v2.3a2.383 2.383 0 0 0-.488 4.741 2.341 2.341 0 0 0 .488 0v2.3a2.383 2.383 0 0 0-.478 4.742 2.312 2.312 0 0 0 .235.012 1.635 1.635 0 0 0 .243-.012v2.3a2.383 2.383 0 1 0-.244 4.741 1.635 1.635 0 0 0 .243-.012v2.3a2.383 2.383 0 1 0-.478 4.742 2.312 2.312 0 0 0 .235.012 1.635 1.635 0 0 0 .243-.012v2.3a2.383 2.383 0 1 0-.478 4.742 2.312 2.312 0 0 0 .235.012 1.635 1.635 0 0 0 .243-.012v2.3q-.12-.012-.243-.012a2.383 2.383 0 0 0 0 4.766 1.635 1.635 0 0 0 .243-.012v2.3a2.383 2.383 0 1 0-.478 4.742 2.312 2.312 0 0 0 .235.012 1.635 1.635 0 0 0 .243-.012v4.044a7.843 7.843 0 0 0-7.792 7.842H51.323V.364Z"
            style="fill: #ba0938"
          />
          <path
            id="Path_223"
            data-name="Path 223"
            d="M50.959 0h139.332v.364a7.488 7.488 0 0 0 7.48 7.479h.364v4.816l-.4-.041a2.043 2.043 0 0 0-.206-.01 2.019 2.019 0 0 0-.009 4.038c.072 0 .144 0 .215-.011l.4-.041v3.1l-.4-.04a2.043 2.043 0 0 0-.206-.01 2.019 2.019 0 0 0 0 4.038 1.421 1.421 0 0 0 .206-.01l.4-.04v3.1l-.4-.04a2.043 2.043 0 0 0-.206-.01 2.019 2.019 0 0 0 0 4.038 1.421 1.421 0 0 0 .206-.01l.4-.04v3.1l-.4-.041a2.019 2.019 0 0 0-.421 4.016c.071.007.143.011.215.011a1.421 1.421 0 0 0 .206-.01l.4-.04v3.1l-.4-.041a2.043 2.043 0 0 0-.206-.01 2.019 2.019 0 0 0-.009 4.038c.072 0 .144 0 .215-.011l.4-.041v3.1l-.4-.04a2.043 2.043 0 0 0-.206-.01 2.019 2.019 0 1 0 0 4.038 2.029 2.029 0 0 0 .206-.01l.4-.041v3.1l-.4-.041a2.019 2.019 0 1 0-.206 4.028 1.415 1.415 0 0 0 .206-.01l.4-.041v3.1l-.4-.04a2.019 2.019 0 0 0-.421 4.016c.071.007.143.011.215.011a1.415 1.415 0 0 0 .206-.01l.4-.041v3.1l-.4-.04a2.019 2.019 0 1 0 0 4.017l.4-.041V77.7h-.362a7.5 7.5 0 0 0-7.43 7.478v.364H50.959Zm146.447 77.011v-3.323a2.747 2.747 0 0 1 0-5.489v-1.551a2.747 2.747 0 0 1 0-5.489v-1.55a2.747 2.747 0 0 1 0-5.489v-1.551a2.747 2.747 0 0 1 0-5.489v-1.55a2.747 2.747 0 0 1 0-5.489V38.49a2.747 2.747 0 0 1 0-5.489v-1.55a2.747 2.747 0 0 1 0-5.489v-1.551a2.747 2.747 0 0 1 0-5.489v-1.55a2.747 2.747 0 0 1 0-5.489V8.564A8.219 8.219 0 0 1 189.57.728H51.69v84.114h137.932a8.231 8.231 0 0 1 7.784-7.832"
            style="fill: #c64e1d"
          />
          <path
            id="Path_224"
            data-name="Path 224"
            d="M.485 12a.811.811 0 0 0-.121.006V7.964A7.843 7.843 0 0 0 8.182.121h42.9v84.842H8.233A7.844 7.844 0 0 0 .39 77.121H.364v-4.044c.04 0 .08.006.121.006a2.383 2.383 0 0 0 0-4.766.811.811 0 0 0-.121.006v-2.285c.04 0 .08.006.121.006a2.383 2.383 0 1 0 0-4.766.811.811 0 0 0-.121.006V59h.121a2.383 2.383 0 0 0 0-4.766.811.811 0 0 0-.121.006v-2.281c.04 0 .08.006.121.006a2.383 2.383 0 0 0 0-4.766.811.811 0 0 0-.121.006v-2.284c.04 0 .08.006.121.006a2.383 2.383 0 0 0 0-4.766.811.811 0 0 0-.121.006v-2.288c.04 0 .08.006.121.006a2.383 2.383 0 0 0 0-4.766H.364V30.84c.04 0 .08.006.121.006a2.383 2.383 0 0 0 0-4.766.811.811 0 0 0-.121.006V23.8c.04 0 .08.006.121.006a2.383 2.383 0 1 0 0-4.766.811.811 0 0 0-.121.006v-2.285c.04 0 .08.006.121.006a2.383 2.383 0 0 0 0-4.767"
            style="fill: #f2ebd8"
          />
          <path
            id="Path_225"
            data-name="Path 225"
            d="M189.926.121a7.842 7.842 0 0 0 7.843 7.843v4.049a2.383 2.383 0 1 0-.478 4.742 2.312 2.312 0 0 0 .235.012 1.635 1.635 0 0 0 .243-.012v2.3a2.383 2.383 0 1 0-.478 4.742 2.312 2.312 0 0 0 .235.012 1.635 1.635 0 0 0 .243-.012v2.3a2.383 2.383 0 1 0-.478 4.742 2.312 2.312 0 0 0 .235.012 1.635 1.635 0 0 0 .243-.012v2.3a2.383 2.383 0 1 0-.478 4.742 2.312 2.312 0 0 0 .235.012 1.635 1.635 0 0 0 .243-.012v2.3q-.12-.012-.243-.012a2.383 2.383 0 0 0 0 4.766 1.635 1.635 0 0 0 .243-.012v2.3q-.12-.012-.243-.012a2.383 2.383 0 0 0 0 4.766 1.635 1.635 0 0 0 .243-.012v2.3a2.383 2.383 0 1 0-.478 4.742 2.312 2.312 0 0 0 .235.012 1.635 1.635 0 0 0 .243-.012v2.3q-.12-.012-.243-.012a2.383 2.383 0 0 0 0 4.766 1.635 1.635 0 0 0 .243-.012v2.3q-.12-.012-.243-.012a2.383 2.383 0 0 0 0 4.766 1.635 1.635 0 0 0 .243-.012v4.03a7.843 7.843 0 0 0-7.792 7.842H51.08V.121Z"
            style="fill: #f55c1e"
          />
          <path
            id="Rectangle_154"
            data-name="Rectangle 154"
            style="fill: #c4bfb1"
            d="M50.901.121h.364v84.842h-.364z"
          />
          <path
            id="Rectangle_155"
            data-name="Rectangle 155"
            class="cls-9"
            d="M50.901 0h.364v.728h-.364z"
          />
          <path
            id="Path_226"
            data-name="Path 226"
            class="cls-9"
            d="M50.9 81.891h.364v1.476H50.9Zm0-2.951h.364v1.475H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364V45H50.9Zm0-2.951h.364v1.468H50.9Zm0-2.951h.364V39.1H50.9Zm0-2.951h.364v1.47H50.9Zm0-2.951h.364V33.2H50.9Zm0-2.951h.364v1.472H50.9Zm0-2.951h.364V27.3H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.48H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.467H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364v1.476H50.9Zm0-2.951h.364V3.69H50.9Z"
          />
          <path
            id="Rectangle_156"
            data-name="Rectangle 156"
            class="cls-9"
            d="M50.901 84.842h.364v.728h-.364z"
          />
        </g>
      </g>
      <g
        style="clip-path: url(#clip-path-2)"
        id="Group_284"
        data-name="Group 284"
      >
        <g id="Group_36" data-name="Group 36">
          <path
            id="Path_24"
            data-name="Path 24"
            d="M22.3 28.081a16.744 16.744 0 0 1 9.655.024 1.943 1.943 0 0 1 1.134 3.049 228.476 228.476 0 0 0-4.232 5.688 2.271 2.271 0 0 1-3.629-.011l-4.08-5.765a1.945 1.945 0 0 1 1.152-2.985"
            style="fill: #f55c04"
          />
          <path
            id="Path_25"
            data-name="Path 25"
            class="cls-12"
            d="M27.072 55.778c-2.933 0-12.5-.7-12.5-9.637a12.363 12.363 0 0 1 12.5-12.194 12.363 12.363 0 0 1 12.5 12.194c0 8.942-9.565 9.637-12.5 9.637m0-23.991a14.526 14.526 0 0 0-14.658 14.354c0 7.277 5.616 11.8 14.658 11.8s14.658-4.52 14.658-11.8a14.526 14.526 0 0 0-14.658-14.354"
          />
          <path
            id="Path_26"
            data-name="Path 26"
            class="cls-12"
            d="M31.52 44.95a1.08 1.08 0 1 0 0-2.16h-2.607l1.911-2.2a1.079 1.079 0 1 0-1.607-1.44l-.022.025-2.188 2.514-2.035-2.489a1.08 1.08 0 1 0-1.672 1.367l1.817 2.222h-2.493a1.08 1.08 0 1 0 0 2.16h3.368v1.582h-3.368a1.08 1.08 0 1 0 0 2.16h3.368v2.15a1.08 1.08 0 0 0 2.16 0v-2.15h3.368a1.08 1.08 0 1 0 0-2.16h-3.368V44.95Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>