<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="w-6 h-6 fill-current"
    viewBox="0 0 24 23.75"
  >
    <defs>
      <style>
        .cls-1 {
          fill: #fff;
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="Group_345" data-name="Group 345">
          <path
            id="Path_119"
            data-name="Path 119"
            class="cls-1"
            d="M17.055 4.434a1.643 1.643 0 0 0-.14-.253L14.7.815a1.813 1.813 0 0 0-2.668-.4l-3.8 3.15a1.8 1.8 0 0 0-.58.865Z"
          />
          <path
            id="Path_120"
            data-name="Path 120"
            class="cls-1"
            d="M22.327 15.36a.676.676 0 0 1-.674.676h-4.661a2.362 2.362 0 0 1 0-4.72h4.663a.675.675 0 0 1 .675.673Zm-7.863 6.72H7.6a5.829 5.829 0 0 1-5.93-5.711v-5.39A5.829 5.829 0 0 1 7.6 5.268h6.868a5.786 5.786 0 0 1 5.8 4.377h-3.277a4.029 4.029 0 0 0-.113 8.058h3.349a5.918 5.918 0 0 1-5.759 4.376m7.5-12.412a7.414 7.414 0 0 0-7.5-6.067H7.6A7.5 7.5 0 0 0 0 10.98v5.39a7.5 7.5 0 0 0 7.6 7.38h6.868a7.586 7.586 0 0 0 7.476-6.064A2.345 2.345 0 0 0 24 15.362v-3.374a2.344 2.344 0 0 0-2.033-2.32"
          />
          <path
            id="Path_121"
            data-name="Path 121"
            class="cls-1"
            d="M16.855 12.786a.887.887 0 1 0 .887.888.888.888 0 0 0-.887-.887"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>