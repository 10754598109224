import { render, staticRenderFns } from "./MyIcon.vue?vue&type=template&id=16b31f41&scoped=true"
import script from "./MyIcon.vue?vue&type=script&lang=js"
export * from "./MyIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b31f41",
  null
  
)

export default component.exports