export const state = () => ({
  records: [],
  meta: {},
  chat: 'new',
  cancelToken: null // 创建 CancelToken 实例
})


export const getters = {
  list({ records }) {
    // Sort the records by id
    return [...records].sort((a, b) => a.id - b.id);
  },
}


export const mutations = {
  SET_MESSAGES(state, data) {
    state.records = data.data;
    state.data = data.meta
  },
  SET_CHAT(state, data) {
    state.chat = data
  },
  ADD_MESSAGE(state, data) {
    state.records.push(...data)
  },
  SET_MESSAGE_BY_ID(state, { tempId, chat }) {
    const recordToUpdate = state.records.find(r => r.id === tempId);
    if (recordToUpdate) {
      recordToUpdate.content = chat.content;
    }
  },
  CLEAR_MESSAGE(state) {
    state.records = [];
    state.data = [];
  },
  REMOVE_MESSAGE_BY_IDS(state, ids) {
    state.records = state.records.filter(record => !ids.includes(record.id));
  },
  SET_CANCEL_TOKEN(state, cancelToken) {
    state.cancelToken = cancelToken;
  }
}

export const actions = {

  fetch({ commit, dispatch, state }, chat) {

    commit('SET_CANCEL_TOKEN', this.$axios.CancelToken.source());// 创建新的 CancelToken 实例

    return this.$axios.get(`/chat/${chat}/messages`, {
      cancelToken: state.cancelToken.token // 将 CancelToken 的 token 属性传递给 Axios 请求
    }).then(({ data }) => {
      commit('SET_MESSAGES', data);
    }).catch((error) => {
      if (this.$axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      }
    })
  },
  cancelFetch({ state }) {
    if (state.cancelToken) {
      state.cancelToken.cancel("Request canceled");
    }
  },
  addMessage({ commit }, data) {
    commit('ADD_MESSAGE', data);
  },

  setMessageById({ commit }, newChat) {
    commit('SET_MESSAGE_BY_ID', newChat);
  },
  clearMessage({ commit }) {
    commit('CLEAR_MESSAGE');
  },
  removeMessageByIds({ commit }, ids) {
    commit('REMOVE_MESSAGE_BY_IDS', ids);
  }

} 