<template>
  <div class="flex flex-col max-h-screen overflow-hidden">
    <div class="bg-white flex-none">
      <div class="px-4 pt-6 pb-4 flex items-center justify-between">
        <button type="button" class="flex-none" @click="close">
          <XIcon />
        </button>
        <p class="flex-grow text-center">
          <AuthIcon class="inline-block mr-2" />{{ title }}
        </p>
      </div>
    </div>

    <ForgotForm v-if="mode == 'forgot'" />

    <SigninForm v-if="mode == 'signin'" @changeto="changeto" @close="close" />

    <SignupForm v-if="mode == 'signup'" @changeto="changeto" :agree="agree" />

    <SignupTerms v-if="mode == 'terms'" @changeto="changeto" />
  </div>
</template>

<script>
import { XIcon } from "@vue-hero-icons/outline";
import SigninForm from "@/components/dating/auth/SigninForm";
import SignupForm from "@/components/dating/auth/SignupForm";
import SignupTerms from "@/components/dating/auth/SignupTerms";
import ForgotForm from "@/components/dating/auth/ForgotForm";
import AuthIcon from "@/components/icons/AuthIcon";
export default {
  props: {
    signupText: {
      type: String,
      default: '註冊成為會員'
    },
  },
  components: {
    XIcon,
    SigninForm,
    SignupForm,
    SignupTerms,
    ForgotForm,
    AuthIcon,
  },
  data() {
    return {
      mode: "signup",
      agree: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    changeto(mode, agree) {
      this.mode = mode;
      if (agree) this.agree = agree;
    },
  },
  computed: {
    title() {
      if (this.mode == "signup") return "註冊成為月老";

      if (this.mode == "forgot") return "忘記密碼";

      if (this.mode == "terms") return "會員守則及條款";

      return "登入";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>